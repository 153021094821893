import { DomainConfiguration, makeApplicationConfig } from "../authentication/ApplicationConfiguration"

export const apiConfiguration = Object.freeze(makeApiConfiguration(makeApplicationConfig()))

export interface ApiItemConfiguration {
  url: string
  scopes(): Array<string>
  domainConfig: DomainConfiguration
}

export interface ApiConfiguration {
  graphQLApi: ApiItemConfiguration
  onboardingApi: ApiItemConfiguration
  microsoftGraph: ApiItemConfiguration
}

function makeApiConfiguration(config: DomainConfiguration): ApiConfiguration {
  const scopeDomain = `https://${config.authorityDomainName}.onmicrosoft.com`
  return {
    graphQLApi: {
      url: config.isLocal ? "/api/TenantQL" : "/gql",
      domainConfig: config,
      scopes(write = true): Array<string> {
        const scopes = [
          // These scopes have been applied to the client application and come from the server/api application
          // From these the audience is determined
          `${scopeDomain}/api/api.read`,
        ]

        if (write) {
          scopes.push(`${scopeDomain}/api/api.write`)
        }
        return scopes
      },
    },
    onboardingApi: {
      url: "/provisioning/api/",
      domainConfig: config,
      scopes(): Array<string> {
        const scopes = [
          // Api manager scopes
          `${scopeDomain}/provisioning-service/onboarding`,
        ]

        return scopes
      },
    },
    microsoftGraph: {
      url: "https://graph.microsoft.com/v1.0",
      domainConfig: config,
      scopes: () => ["https://graph.microsoft.com/offline_access", "https://graph.microsoft.com/openid"],
    },
  }
}
