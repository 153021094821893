import { Chip } from "@mui/material"
import Box from "@mui/material/Box"
import React from "react"
import { Role } from "../../../../api/provisioning/clientTypes"

type ComponentProps = {
  userRoles: Array<Role>
}

export const UserRolesView: React.FC<ComponentProps> = (props) => {
  // Cannot mutate state data!!
  const roles = [...props.userRoles]
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {roles
        .sort((x1, x2) => x1.displayOrder - x2.displayOrder)
        .map((value) => (
          <Chip
            key={value.name}
            label={value.name}
            sx={{ fontSize: 16, textTransform: "capitalize", color: "green" }}
          />
        ))}
    </Box>
  )
}
