import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit"
import { UserProfileResponse } from "../../api/tenantQL/DeviceQueryResponse"

export interface UserProfile {
  id: string
  userPrincipalName: string
  displayName: string
  givenName?: string
  surname?: string
  preferredLanguage?: string
  mail?: string
  country?: string
  tenantUrn?: string

  // businessPhones:Array<string>;
  // jobTitle:string;
  // mobilePhone:string;
  // officeLocation:string;
}
export interface AuthToken {
  accessToken: string
  expiresOn: number
  idToken: string
}
export interface AuthReducerState {
  userProfile?: UserProfile
  interactiveGrapLoginRequired: boolean
  error?: SerializedError
  isLoading: boolean
}

const iniitalState: AuthReducerState = {
  userProfile: undefined,
  interactiveGrapLoginRequired: false,
  error: undefined,
  isLoading: false,
}

const additionalActions = {
  makeFetchUserProfile: createAsyncThunk(
    "auth/profile/fetch",
    async (promise: Promise<UserProfileResponse>) => {
      return await promise
    },
  ),
}

const reducerSlice = createSlice({
  name: "authReducer",
  initialState: iniitalState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(additionalActions.makeFetchUserProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(additionalActions.makeFetchUserProfile.fulfilled, (state, action) => {
        const { profile } = action.payload as UserProfileResponse
        if (!profile) {
          state.userProfile = undefined
        } else {
          state.userProfile = {
            id: profile.id,
            displayName: profile.displayName,
            userPrincipalName: profile.userPrincipalName,
            mail: profile.mail,
            givenName: profile.givenName,
            surname: profile.surname,
            country: profile.country,
            preferredLanguage: profile.preferredLanguage,
            tenantUrn: profile.tenantUrn,
          }
        }
        state.isLoading = false
      })
      .addCase(additionalActions.makeFetchUserProfile.rejected, (state, action) => {
        state.error = action.error
        state.userProfile = undefined
        state.isLoading = false
      })
  },
})

export const AuthReducer = {
  reducer: reducerSlice.reducer,
  ...additionalActions,
}
