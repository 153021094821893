import { HttpClient, HttpHeader, HttpResponse } from "./HttpClientTypes"

export type FetchOptions = {
  nounce: () => Promise<string>
}

const defaultFetchOptions: FetchOptions = { nounce: () => Promise.resolve("") }

export function makeFetchHttpClient(
  hostUrl: string,
  options: FetchOptions = defaultFetchOptions,
): HttpClient {
  function makeHttpResponse(requestUri: URL, requestVerb: string, response: Response): Promise<HttpResponse> {
    const headers: Array<HttpHeader> = Array.from(response.headers).map((x: Array<string>) => ({
      key: x[0],
      value: x[1],
    }))

    return response
      .text()
      .then((responseText) => ({
        requestVerb: requestVerb,
        requestUri: requestUri.href,
        status: response.status,
        bodyText: responseText,
        headers: headers,
      }))
      .catch((e) => ({
        requestVerb: requestVerb,
        requestUri: requestUri.href,
        status: response.status,
        bodyText: e.message,
        headers: headers,
      }))
  }

  function makeUrl(pathOrUrl?: string): URL {
    if (!pathOrUrl && !hostUrl) throw new Error("Empty Rest Request Url encountered")
    if (!pathOrUrl) return new URL(hostUrl)
    if (!hostUrl) return new URL(pathOrUrl)

    // If passed an absolute path
    if (pathOrUrl.startsWith("http")) return new URL(pathOrUrl)

    return new URL(pathOrUrl, hostUrl)
  }

  function applyNounce(url: URL): Promise<URL> {
    return options.nounce().then((nounce) => {
      if (nounce) url.searchParams.append("nounce", nounce)
      return url
    })
  }

  function makeRequest(
    url: URL,
    method: "GET" | "HEAD" | "OPTIONS" | "POST" | "PUT" | "PATCH" | "DELETE",
    headers: Array<HttpHeader>,
    body?: string,
  ): Request {
    // mode: "no-cors"

    const request: RequestInit = {
      method,
      body,
      headers: headers.reduce<Record<string, string>>((accumulator, current) => {
        accumulator[current.key] = current.value
        return accumulator
      }, {}),
    }

    const req = new Request(url, request)
    return req
  }

  const nounceClient = {
    get: (uri: string | undefined, requestHeaders: Array<HttpHeader> = []) =>
      applyNounce(makeUrl(uri))
        .then((url) => fetch(makeRequest(url, "GET", requestHeaders)))
        .then((response) => makeHttpResponse(makeUrl(uri), "GET", response)),

    post: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      applyNounce(makeUrl(uri))
        .then((url) => fetch(makeRequest(url, "POST", requestHeaders, body)))
        .then((response) => makeHttpResponse(makeUrl(uri), "POST", response)),

    patch: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      applyNounce(makeUrl(uri))
        .then((url) => fetch(makeRequest(url, "PATCH", requestHeaders, body)))
        .then((response) => makeHttpResponse(makeUrl(uri), "PATCH", response)),

    put: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      applyNounce(makeUrl(uri))
        .then((url) => fetch(makeRequest(url, "PUT", requestHeaders, body)))
        .then((response) => makeHttpResponse(makeUrl(uri), "PUT", response)),

    delete: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      applyNounce(makeUrl(uri))
        .then((url) => fetch(makeRequest(url, "DELETE", requestHeaders, body)))
        .then((response) => makeHttpResponse(makeUrl(uri), "DELETE", response)),
  }

  const plainClient = {
    get: (uri: string | undefined, requestHeaders: Array<HttpHeader> = []) =>
      fetch(makeRequest(makeUrl(uri), "GET", requestHeaders)).then((response) =>
        makeHttpResponse(makeUrl(uri), "GET", response),
      ),

    post: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      fetch(makeRequest(makeUrl(uri), "POST", requestHeaders, body)).then((response) =>
        makeHttpResponse(makeUrl(uri), "POST", response),
      ),
    patch: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      fetch(makeRequest(makeUrl(uri), "PATCH", requestHeaders, body)).then((response) =>
        makeHttpResponse(makeUrl(uri), "PATCH", response),
      ),
    put: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      fetch(makeRequest(makeUrl(uri), "PUT", requestHeaders, body)).then((response) =>
        makeHttpResponse(makeUrl(uri), "PUT", response),
      ),

    delete: (uri: string | undefined, body: string, requestHeaders: Array<HttpHeader>) =>
      fetch(makeRequest(makeUrl(uri), "DELETE", requestHeaders, body)).then((response) =>
        makeHttpResponse(makeUrl(uri), "DELETE", response),
      ),
  }

  return nounceClient
}
