import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { TenantResponse } from "../../api/tenantQL/TenantQL"

export interface TenantReducerState {
  tenant?: TenantResponse
}
const initialState: TenantReducerState = { tenant: undefined }

const additionalActions = {
  fetchTenant: createAsyncThunk("tenant/query", async (promise: Promise<TenantResponse>) => {
    return await promise
  }),
}

const reducerSlice = createSlice({
  name: "tenantReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(additionalActions.fetchTenant.pending, (state, action) => {
        // state.tenant = undefined
      })
      .addCase(additionalActions.fetchTenant.rejected, (state, action) => {
        state.tenant = undefined
      })

      .addCase(additionalActions.fetchTenant.fulfilled, (state, action) => {
        state.tenant = action.payload
      })
  },
})

export const TenantReducer = {
  reducer: reducerSlice.reducer,
  ...additionalActions,
}
