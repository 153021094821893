import { DeviceUrn } from "@pollin8/contracts"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const REDUCER_NAME = "move-device"

export type MoveDeviceStatus = "NotStarted" | "InProgress" | "Completing" | "Complete"
export interface MoveDevicereducer {
  status: MoveDeviceStatus
  toDeviceHardwareId?: string
  fromLogicalDeviceUrn?: DeviceUrn
  fromHardwareId?: string
}
const initialState: MoveDevicereducer = {
  status: "NotStarted",
}

export const moveReducerSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    startNewMoveDevice(state) {
      return { status: "InProgress" }
    },
    setToHardwareDeviceId(state, action: PayloadAction<string | undefined>) {
      const { payload } = action
      state.toDeviceHardwareId = payload
    },
    setFromLogicalDevice(state, action: PayloadAction<DeviceUrn>) {
      const { payload } = action
      state.fromLogicalDeviceUrn = payload
    },
    setFromHardwareDevice(state, action: PayloadAction<string>) {
      const { payload } = action
      state.fromHardwareId = payload
    },
    moveDeviceCompleInProgress(state) {
      state.status = "Completing"
    },
    moveDeviceComplete(state, action: PayloadAction<boolean>) {
      state.status = action ? "Complete" : "InProgress"
    },
  },
})

export const MoveDeviceReducer = {
  ...moveReducerSlice.actions,
}
