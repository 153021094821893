import { Backdrop, Box, CircularProgress, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
type ComponentProps = {
  isLoading: boolean
  messageText?: string
}

const LoadingSpinnerComponent: React.FC<ComponentProps> = (props) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={props.isLoading}>
      <div>
        <CircularProgress />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {props.messageText}
          </Typography>
        </Box>
      </div>
    </Backdrop>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  // loading: {
  //   position: "fixed",
  //   zindex: 999,
  //   overflow: "show",
  //   margin: "auto",
  //   top: 0,
  //   left: 0,
  //   bottom: 0,
  //   right: 0,
  // },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}))

export const LoadingSpinner = LoadingSpinnerComponent
