import React from "react"
import { lazyLoad } from "../lazyLoad"
import { useModalContext } from "./DeviceModalContextProvider"
import { DialogComponent } from "./DialogComponent"

// import { DeviceFilterComponent } from "../../pages/DeviceMapPage/components/DeviceFilterComponent"
const DeviceFilterComponent = lazyLoad(
  () => import("../../pages/DeviceMapPage/components/DeviceFilterComponent"),
  "DeviceFilterComponent",
)

const GlobalDeviceFilterModal: React.FC = () => {
  const { deviceFilter } = useModalContext()

  return (
    <DialogComponent title={"Filter Devices"} isOpen={deviceFilter.isOpen} onClose={deviceFilter.close}>
      {deviceFilter.isOpen && <DeviceFilterComponent onFilterSelected={deviceFilter.close} />}
    </DialogComponent>
  )
}

export const DeviceFilterModalComponent = GlobalDeviceFilterModal
