import { IPublicClientApplication } from "@azure/msal-browser"
import { MsalSilentRequestBuilder } from "../../authentication/authConfig"
import { makeApiClientAuthOptionsFromMSAL } from "../../client/GraphQLClient"
import { makeHttpAuthHandler } from "../../client/httpClient/AuthHandler"
import { makeFetchHttpClient } from "../../client/httpClient/FetchClient"
import { ApiClientOptions, GraphClient, HttpResponse } from "../../client/httpClient/HttpClientTypes"
import { apiConfiguration } from "../apiConfig"
import { TenantMutations, TenantQLQueries } from "./TenantQL"

export interface ClientApi {
  client: GraphClient
  query: TenantQLQueries
  mutation: TenantMutations
}

export function makeGraphQLApi(instance: IPublicClientApplication): ClientApi {
  const { graphQLApi } = apiConfiguration
  const account = instance.getActiveAccount()
  const requestOptions = MsalSilentRequestBuilder.make(graphQLApi.domainConfig)
    .withAccount(account)
    .withScopes(graphQLApi.scopes())
    .toObject()

  const options = makeApiClientAuthOptionsFromMSAL(instance, requestOptions)
  const client = makeGraphQLClient(graphQLApi.domainConfig.baseURL, options)
  return {
    client: client,
    query: new TenantQLQueries(client, graphQLApi),
    mutation: new TenantMutations(client, graphQLApi),
  }
}

function makeGraphQLClient(hostUrl: string, options: ApiClientOptions): GraphClient {
  const authClient = makeHttpAuthHandler(makeFetchHttpClient(hostUrl), options)

  class HttpResponseError extends Error {
    constructor(public response: HttpResponse) {
      super(`${response.requestVerb} '${response.requestUri}' returned status code: ${response.status}`)
    }
  }

  function validateStatus(response: HttpResponse) {
    if (response.status < 400) {
      return response
    }

    throw new HttpResponseError(response)
  }

  function validateBody(response: HttpResponse) {
    if (!response.bodyText || response.bodyText.trim().length === 0) {
      throw new Error(`${response.requestVerb} '${response.requestUri}' response doesn't have any content`)
    }

    return response
  }

  function parseJsonResponse<T>(response: HttpResponse): T {
    try {
      return JSON.parse(response.bodyText).data
    } catch {
      throw new Error(`${response.requestVerb} '${response.requestUri}' response json could not be parsed`)
    }
  }

  const query = <T>(path: string, query: string, variables: unknown) =>
    authClient
      .post(path, JSON.stringify({ query: query, variables: variables }))
      .then(validateStatus)
      .then(validateBody)
      .then((response) => parseJsonResponse<T>(response))

  return {
    query: query,
    mutate: query,
  }
}
