import { AuthenticationResult, EventMessage, EventType, IPublicClientApplication } from "@azure/msal-browser"
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react"
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { ITelemetryItem } from "@microsoft/applicationinsights-web"
import React, { Suspense, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import "./App.css"
import { signIn } from "./authentication/authUtil"
import { DeviceDetailModalComponent } from "./components/Dialogs/DeviceDetailModal"
import { DeviceFilterModalComponent } from "./components/Dialogs/DeviceFilterModal"
import { ModalContextProvider } from "./components/Dialogs/DeviceModalContextProvider"
import { LoadingSpinner } from "./components/LoadingSpinnerComponent"
import { ClientApiContextProvider } from "./components/RestApiClientProvider"
import { WatchPositionComponent } from "./components/WatchPositionComponent"
import { UserInfoContextProvider, useUserInfoContext } from "./components/auth/UserInfoProvider"
import { ServiceWorkerRegistrationComponent } from "./pages/ServiceWorkerRegistrationComponent"
import { SignInComponent } from "./pages/SignInComponent"
import { CustomNavigationClient } from "./utils/CustomNavagationClient"
import TelemetryEvent from "msal/lib-commonjs/telemetry/TelemetryEvent"

export default function App(props: { pca: IPublicClientApplication }): JSX.Element {
  const navagate = useNavigate()
  const appInsights = useAppInsightsContext()

  // Initialise global pca props once on mount
  useEffect(() => {
    const navigationClient = new CustomNavigationClient(navagate)
    props.pca.setNavigationClient(navigationClient)
    props.pca.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const payload = event.payload as AuthenticationResult
        appInsights.trackEvent({
          name: "login",
          properties: {
            userName: payload.account.username,
            sub: payload.idTokenClaims["sub"] ?? "missing",
            tenantUrn: payload.idTokenClaims["tenantUrn"] ?? "missing",
          },
        })
      }
    })
  }, [])

  return (
    <MsalProvider instance={props.pca}>
      <MainContent />
    </MsalProvider>
  )
}

function MainContent(): JSX.Element {
  const msal = useMsal()

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <ClientApiContextProvider>
          <UserInfoContextProvider>
            <WatchPositionComponent />
            <ModalContextProvider>
              <AuthenticatedContent />
            </ModalContextProvider>
          </UserInfoContextProvider>
        </ClientApiContextProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <SignInComponent signInAction={() => signIn(msal.instance)} />
      </UnauthenticatedTemplate>
      <ServiceWorkerRegistrationComponent />
    </React.Fragment>
  )
}

function AuthenticatedContent(): JSX.Element {
  const user = useUserInfoContext()
  const appInsights = useAppInsightsContext()
  useEffect(() => {
    appInsights.getAppInsights().addTelemetryInitializer((envelope: ITelemetryItem) => {
      if (envelope.data) {
        // const  data  = envelope.data as EventTelemetry
        // baseTypeToTelemetryType(data.)
      }
    })
  }, [])
  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} messageText="Downloading Components..." />}>
      <DeviceDetailModalComponent />
      <DeviceFilterModalComponent />
      <Outlet />
    </Suspense>
  )
}
