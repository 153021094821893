import { AsyncThunk, GetThunkAPI, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  AddUserRoleRequest,
  ApiRootResource,
  HardwareDeviceAssignmentResource,
  HardwareResource,
  LogicalDeviceResource,
  ProfileResource,
  ProvisioningApResourceTypes,
  ROOT_LINK,
  ResourceMutatedResponse,
  RoleResource,
  TenantResource,
  TransferHardwareDeviceRequest,
  UpdateUserProfileRequest,
  UserCreateRequest,
  UserProfile,
  UserResource,
  isRestFeed,
  isRestResource,
  isRoleResource,
  resourceRelation,
  resourceSelfUri,
} from "../../../api/provisioning/clientTypes"
import { ProvisioningApiClient } from "../../../api/provisioning/provisioningClient"
import { RelationType, RestFeed, RestLink } from "../../../api/provisioning/restTypes"
import { isDefined } from "../../../common/validation"
import { RootState } from "../../store/rootStore"
import { UserManagementReducerError } from "./UserManagementReducerError"
import { loadResourceRaw } from "./apiLoaders"
import { setFeedResourceRaw, setResourceRaw } from "./applyResource"
import { findCollection, findResource } from "./resourceAccessors"

export type ResourceLoadOptions = {
  forceReload: boolean
  loadResourceTypes: Array<RelationType | "*">
  context: Record<string, { count: number; loaded: boolean }>
}

export interface PartialUserInformation {
  user: UserResource
  profile?: ProfileResource
  roles?: Array<RoleResource>
}

export interface UserManagementReducer {
  apiRoot?: ApiRootResource

  /** The currently logged in user */
  currentTenant?: TenantResource
  currentUser?: PartialUserInformation

  /** The currently selected user */
  selectedUser?: PartialUserInformation
  selectedUserLink?: RestLink

  allResources: Record<string, ProvisioningApResourceTypes | null>
  allFeeds: Record<string, RestFeed>

  isLoading: boolean
  pendingActions: number
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, GetThunkAPI<unknown>>
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>
// type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>

const REDUCER_NAME = "provisioning"

const initialState: UserManagementReducer = {
  allResources: {},
  allFeeds: {},
  isLoading: false,
  pendingActions: 0,
}

export const loadResource = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "load-resource"),
  async (
    arg: {
      api: ProvisioningApiClient
      link: RestLink
      options?: Partial<ResourceLoadOptions>
    },
    { dispatch, getState },
  ) => {
    const { userManagement } = getState() as RootState

    const { link } = arg
    console.info(`**Load Resource**: ${link.rel}`, { link })
    // const { api, link } = arg
    // const { setResource, setFeedResource } = userManagementSlice.actions
    // const defaultResourceLoadOptions: ResourceLoadOptions = {
    //   forceReload: false,
    //   loadResourceTypes: [],
    //   context: {},
    // }
    // const opt = {
    //   ...defaultResourceLoadOptions,
    //   ...arg.options,
    // }

    // const findResource = (state: UserManagementReducer, link: RestLink) =>
    //   state.allResources[link.uri] || state.allFeeds[link.uri]

    // // Existing resource Resource is already loaded
    // const existingResource = findResource(userManagement, link)
    // if (!existingResource || opt.forceReload === true) {
    //   // Check for errors
    //   console.info(`Load Resource: ${link.rel}`, { link })
    //   const response = await api.loadResource(link)
    //   if (response.status >= 400) {
    //     console.error("Load Error", { link, status: response.status })
    //     const errorResponse: ErrorResponse = {
    //       type: "error",
    //       properties: {
    //         statusCode: response.status,
    //         description: response.bodyText,
    //       },
    //       relations: [{ rel: "self", uri: link.uri }],
    //     }
    //     userManagement.allResources[link.uri] = errorResponse
    //     return errorResponse
    //   }

    //   // Cycle detection - After load
    //   opt.context[link.uri] = recordVisit(opt.context[link.uri], true)

    //   const { data } = response
    //   await (isRestFeed(data) ? dispatch(setFeedResource(data)) : dispatch(setResource(data)))

    //   // Do we need to wait for this ?
    //   await loadChildrenRelations(api, data, opt, dispatch)
    //   return data
    // } else {
    //   // Cycle detection - After load
    //   opt.context[link.uri] = recordVisit(opt.context[link.uri], false)
    //   await loadChildrenRelations(api, existingResource, opt, dispatch)
    //   return existingResource
    // }

    return loadResourceRaw(arg, userManagement, dispatch)
  },
)

export const loadProvisioningApi = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "root"),
  async (arg: { api: ProvisioningApiClient; options?: Partial<ResourceLoadOptions> }, { dispatch }) => {
    const { api, options } = arg
    dispatch(loadResource({ api, link: { rel: "root", uri: "root" }, options }))
  },
)
export const loadCurrentUserInfo = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "user/currentuser"),
  async (arg: { api: ProvisioningApiClient }, { dispatch }) => {
    const { api } = arg

    dispatch(
      loadResource({
        api,
        link: ROOT_LINK,
        options: {
          loadResourceTypes: [
            "roles-collection",
            "role",
            "tenant",
            "currentUser",
            "user.profile",
            "user.role-collection",
          ],
        },
      }),
    )
  },
)
export const updateProfile = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "user/updateUserProfile"),
  async (
    arg: { api: ProvisioningApiClient; user: UserResource; profile: Partial<UserProfile> },
    { dispatch },
  ) => {
    const { api, user, profile } = arg
    if (Object.values(profile).filter(isDefined).length === 0) {
      console.info("No Profile changes exitng....", profile)
      return
    }

    const userProfileLink = resourceRelation(user, "user.profile")
    const request: UpdateUserProfileRequest = {
      updates: profile,
    }

    const response = await api.patchResource(userProfileLink, request)
    if (response.status >= 400) {
      console.error(response)
      return false
    }

    await dispatch(
      loadResource({
        api,
        link: userProfileLink,
        options: { forceReload: true },
      }),
    )
    await dispatch(
      loadResource({
        api,
        link: resourceRelation(user, "self"),
        options: { forceReload: true },
      }),
    )
    return true
  },
)
export const updateUserRoles = createAsyncThunk(
  "provisioning/user/updateUserRoles",
  async (
    arg: {
      api: ProvisioningApiClient
      user: UserResource
      roles: { add: Array<RoleResource>; remove: Array<RoleResource> }
    },
    { dispatch },
  ) => {
    const { api, user, roles } = arg
    if (roles.add.length + roles.remove.length === 0) {
      console.info("No Role changes exitng....")
      return
    }

    const userRolesLink = resourceRelation(user, "user.role-collection")
    const request: AddUserRoleRequest = {
      add: roles.add.map((x) => resourceSelfUri(x)),
      remove: roles.remove.map((x) => resourceSelfUri(x)),
    }
    const response = await api.createResource<ResourceMutatedResponse, AddUserRoleRequest>(
      userRolesLink,
      request,
    )
    if (response.status >= 400) {
      console.error(response)
    }

    await dispatch(
      loadResource({
        api,
        link: response.data.location,
        options: { forceReload: true, loadResourceTypes: ["role"] },
      }),
    )
  },
)
export const createNewUser = createAsyncThunk(
  "provisioning/user/createNewUser",
  async (arg: { api: ProvisioningApiClient; user: UserCreateRequest }, { dispatch, getState }) => {
    const { api, user } = arg
    const response = await api.createResource<ResourceMutatedResponse, UserCreateRequest>(
      { rel: "user", uri: "user" },
      user,
    )
    console.log("Headers", JSON.stringify(response, null, 2))
    if (response.status !== 201) throw new Error("Failed to Create User")

    //response.headers.find((x) => x.key.toLowerCase() === "location")?.value ||
    const newUserLocation = response.data.location
    if (!newUserLocation) throw new Error("Failed to Get New User Location from Response")
    dispatch(
      loadResource({
        api,
        // link: { rel: "user", uri: newUserLocation },
        link: newUserLocation,
        options: { loadResourceTypes: [] },
      }),
    )

    const { userManagement } = getState() as RootState
    if (userManagement.currentTenant) {
      const tenantMemberLink = resourceRelation(userManagement.currentTenant, "tenant.member-collection")
      dispatch(
        loadResource({
          api,
          link: tenantMemberLink,
          options: { forceReload: true },
        }),
      )
    }
  },
)
export const setSelectedUserAsync = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "user/setSelectedUser"),
  async (arg: { api: ProvisioningApiClient; user: UserResource }, thunkApi) => {
    const { user } = arg
    const selfUri = resourceSelfUri(user)
    if (user.type !== "user")
      throw new UserManagementReducerError("Expected type was user", ["Failed to set current user"])

    const { userManagement: state } = thunkApi.getState() as RootState
    state.allResources[selfUri] = user
    const profileResource = findResource(state, user, "user.profile")
    const roleCollection = findCollection(state, user, "user.role-collection")
    state.selectedUser = {
      user: user,
      profile: profileResource as ProfileResource,
      roles: roleCollection.filter(isRoleResource),
    }
  },
)
export const fetchSelectedUser = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "user/fetchSelectedUser"),
  async (arg: { api: ProvisioningApiClient; link: RestLink }, thunkApi) => {
    const { api, link: userLink } = arg
    if (userLink.rel !== "user")
      throw new UserManagementReducerError("Expected type was user", ["Failed to set current user"])

    const user = await thunkApi
      .dispatch(
        loadResource({
          api,
          link: userLink,
          options: {
            loadResourceTypes: ["role", "tenant", "currentUser", "user.profile", "user.role-collection"],
          },
        }),
      )
      .unwrap()

    if (isRestResource(user) && user.type === "user") return user
    return undefined
  },
)
export const transferDevice = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "transferDevice"),
  async (
    arg: {
      api: ProvisioningApiClient
      toHardwareDevice: HardwareResource
      tologicalDevice: LogicalDeviceResource
      fromHardware: HardwareResource | undefined
    },
    thunkApi,
  ) => {
    const resourcesInvalidated: Array<RestLink> = []
    const { getState, dispatch } = thunkApi
    const { api, toHardwareDevice, fromHardware, tologicalDevice } = arg
    const { userManagement } = getState() as RootState

    // Invalidate the toLogical Device assignment collection as it is being replaced witha new logical Device
    if (toHardwareDevice) {
      // We must also invalidate the logicalDevice collection associated with this logicalDevice
      const toDeviceAssingmentResource = findResource<HardwareDeviceAssignmentResource>(
        userManagement,
        toHardwareDevice,
        "hardware-device-assignment",
      )

      if (toDeviceAssingmentResource?.properties.enabled) {
        const toExistingLogicalDevice = findResource(
          userManagement,
          toDeviceAssingmentResource,
          "logical-device",
        )

        if (toExistingLogicalDevice) {
          const toExistingLogicalDeviceCollection = resourceRelation(
            toExistingLogicalDevice,
            "logical-device-assignment-collection",
          )
          if (toExistingLogicalDeviceCollection) {
            resourcesInvalidated.push(toExistingLogicalDeviceCollection)
          }
        }
      }
    }

    // Delete the from resource to ensure any cache is invalidated
    if (fromHardware) {
      const fromDeviceAssignmentLink = resourceRelation(fromHardware, "hardware-device-assignment")
      await dispatch(deleteResource({ api, resource: fromDeviceAssignmentLink, resourceToInvalidate: [] }))
    }

    // Is this a PUT or POSt ?
    const toDeviceAssignmentLink = resourceRelation(toHardwareDevice, "hardware-device-assignment")
    const request: TransferHardwareDeviceRequest = {
      fromHardwareDeviceId: fromHardware?.properties.hwDeviceId,
      logicalDeviceId: tologicalDevice.properties.logicalId,
    }
    const response = await api.putResource<ResourceMutatedResponse, TransferHardwareDeviceRequest>(
      toDeviceAssignmentLink,
      request,
    )

    if (response.status >= 400) {
      console.error(response)
      return { success: false }
    }

    // Resources that must be invalidated
    const fromLogicalDeviceAssignmentsLink = resourceRelation(
      tologicalDevice,
      "logical-device-assignment-collection",
    )
    const allLogicalDeviceAssignmentsLink = resourceRelation(tologicalDevice, "logical-device-collection")
    resourcesInvalidated.push(allLogicalDeviceAssignmentsLink)
    resourcesInvalidated.push(fromLogicalDeviceAssignmentsLink)
    resourcesInvalidated.push(toDeviceAssignmentLink)

    // Maybe put this on logical device

    if (userManagement.apiRoot) {
      const hardwareDeviceAssignmentsLink = resourceRelation(
        userManagement.apiRoot,
        "hardware-device-assignment-collection",
      )
      resourcesInvalidated.push(hardwareDeviceAssignmentsLink)
    }

    resourcesInvalidated.map((x) => {
      dispatch(
        loadResource({
          api,
          link: x,
          options: { forceReload: true, loadResourceTypes: [] },
        }),
      )
    })

    // maybe just dispatch a delete ?
    // resourcesRemoved.map((x) => thunkApi.dispatch(invalidateResource(x)))
    return { success: true }
  },
)
export const deleteResource = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "delete"),
  async (
    arg: {
      api: ProvisioningApiClient
      resource: RestLink
      resourceToInvalidate: Array<RestLink>
    },
    thunkApi,
  ) => {
    const { api, resource, resourceToInvalidate } = arg
    const { invalidateResource } = userManagementSlice.actions
    const response = await api.deleteResource<ResourceMutatedResponse>(resource)

    // Invalidate local cache
    await thunkApi.dispatch(invalidateResource(resource))

    // Invalidate any other resources, this should only include collections as other resources are cached
    resourceToInvalidate.map((x) => {
      thunkApi.dispatch(
        loadResource({
          api,
          link: x,
          options: { forceReload: true, loadResourceTypes: [] },
        }),
      )
    })

    return { success: response.status < 400, response }
  },
)
export const createResource = createAsyncThunk(
  makeActionPath(REDUCER_NAME, "create"),
  async (
    arg: { api: ProvisioningApiClient; resource: RestLink; body: object }, //feedToInvalidate: RestLink
    thunkApi,
  ) => {
    const { api, resource, body } = arg
    const { setFeedResource } = userManagementSlice.actions
    try {
      const response = await api.createResource<ResourceMutatedResponse>(resource, body)

      const [newResourceResponse, feedResponse] = await Promise.all([
        api.loadResource(response.data.location),
        api.loadResource(resource),
      ])

      // if (feedResponse.status >= 400) throw feedResponse
      if (isRestFeed(feedResponse.data)) await thunkApi.dispatch(setFeedResource(feedResponse.data))

      // if (newResourceResponse.status >= 400) throw newResourceResponse
      return newResourceResponse.data
    } catch (e) {
      console.error(e)
      return thunkApi.rejectWithValue(e)
    }
  },
)

// makeProvisioningClient
export const userManagementSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setResource(state, action: PayloadAction<ProvisioningApResourceTypes>) {
      const { payload } = action
      setResourceRaw(state, payload)
    },
    setFeedResource(state, action: PayloadAction<RestFeed>) {
      const { payload } = action
      setFeedResourceRaw(state, payload)
    },
    invalidateResource(state, action: PayloadAction<RestLink>) {
      const link = action.payload

      // Is Resource
      if (state.allResources[link.uri]) {
        const clonedResource = { ...state.allResources }
        console.warn("DELETING", link)
        delete clonedResource[link.uri]

        return { ...state, allResources: clonedResource }
      }
      if (state.allFeeds[link.uri]) {
        const clonedFeed = { ...state.allFeeds }
        console.warn("DELETING", link)
        delete clonedFeed[link.uri]

        return { ...state, allFeeds: clonedFeed }
      }
    },

    setSelectedUser(state, action: PayloadAction<UserResource>): void {
      const { payload } = action
      const selfUri = resourceSelfUri(payload)
      if (payload.type !== "user")
        throw new UserManagementReducerError("Expected type was user", ["Failed to set current user"])

      state.allResources[selfUri] = payload
      const profileResource = findResource<ProfileResource>(state, payload, "user.profile")
      const roleCollection = findCollection<RoleResource>(state, payload, "user.role-collection")
      state.selectedUser = {
        user: payload,
        profile: profileResource,
        roles: roleCollection,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(transferDevice.fulfilled, (state, action) => {
      //   if (action.payload.success === false) return state
      //   const clonedResource = { ...state.allResources }
      //   action.payload.resourcesRemoved.forEach((x) => {
      //     console.warn("DELETING", x)
      //     if (clonedResource[x.uri]) delete clonedResource[x.uri]
      //   })
      //   return { ...state, allResources: clonedResource }
      // })
      // .addCase(deleteResource.fulfilled, (state, action) => {
      //   if (action.payload.success === false) return state
      //   const clonedResource = { ...state.allResources }
      //   action.payload.resourcesRemoved.forEach((x) => {
      //     console.warn("DELETING", x)
      //     if (clonedResource[x.uri]) delete clonedResource[x.uri]
      //   })
      //   return { ...state, allResources: clonedResource }
      // })
      .addCase(fetchSelectedUser.pending, (state) => (state.selectedUser = undefined))
      .addCase(fetchSelectedUser.fulfilled, (state, action) => {
        const user = action.payload
        if (!user) return
        state.selectedUser = {
          user,
          profile: findResource<ProfileResource>(state, user, "user.profile"),
          roles: findCollection<RoleResource>(state, user, "user.role-collection"),
        }
      })
      .addCase(createResource.fulfilled, (state, action) => {
        const resource = action.payload
        const uri = resourceSelfUri(resource)
        if (isRestFeed(resource)) state.allFeeds[uri] = resource
        else state.allResources[uri] = resource
      })

      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is RejectedAction =>
          action.type.startsWith(REDUCER_NAME) &&
          (action.type.endsWith("/rejected") || action.type.endsWith("/fulfilled")),
        (state) => {
          if (state.pendingActions > 0) state.pendingActions--
          state.isLoading = state.pendingActions > 0
        },
      )
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is PendingAction =>
          action.type.startsWith(REDUCER_NAME) && action.type.endsWith("/pending"),
        (state) => {
          state.pendingActions++
          state.isLoading = true
        },
      )
  },
})

function makeActionPath(reducerName: string, ...args: Array<string>) {
  const parts = [reducerName].concat(args)
  return parts.join("/")
}
export async function applyResource(dispatch, data: ProvisioningApResourceTypes): Promise<void> {
  dispatch(
    isRestFeed(data)
      ? userManagementSlice.actions.setFeedResource(data)
      : userManagementSlice.actions.setResource(data),
  )
}
