import { DeviceSchemaUrn, UserRoleName } from "@pollin8/contracts"
import { UserInformation } from "../../pages/UserManagementPage/UserManagementPage"
import { BaseRestResource, RelationType, RestFeed, RestLink, RestResource } from "./restTypes"

// RestResource
export type ProvisioningApiErrorResponse = {
  name: string
  status: number
  message: string
  detail: Array<string>
  stackTrace?: string | Array<string>
}

export type SoftwareVersion = {
  name: string
  softwareVersion: string
  softwareDate: string
}

export type User = {
  displayName: string
  identity: string
  tenantUrn: string
  isCurrent?: boolean
}

export type UserProfile = {
  accountEnabled: boolean
  displayName: string
  userPrincipalName: string
  mailNickname: string
  emailAddress: string
  mobilePhone: string
}

export type Tenant = {
  name: string
  tenantUrn: string
}

export type Role = {
  id: UserRoleName
  name: string
  description: string
  displayOrder: number
}

export interface UserCreateRequest {
  displayName: string
  emailAddress: string
  initialPassword: string
  mailNickName?: string
}
export interface CreateLogicalDeviceRequest {
  logicalDeviceId: string
  logicalSchemaUrn: DeviceSchemaUrn
}

export type ResourceMutatedResponse = { success: boolean; location: RestLink }

export interface TypedRestResource<U extends RelationType, T extends object, EXT extends object = object>
  extends RestResource<T, EXT> {
  type: U
}

export type UserResource = TypedRestResource<"user", User>
export type RoleResource = TypedRestResource<"role", Role>
export type ProfileResource = TypedRestResource<"user.profile", UserProfile>
export type TenantResource = TypedRestResource<"tenant", Tenant>
export type ErrorResponse = TypedRestResource<"error", { statusCode: number; description: string }>

export type ProvisioningApiUserResourceTypes =
  | TypedRestResource<"currentUser", User>
  | RoleResource
  | UserResource
  | ProfileResource
  | TenantResource

export type HardwareDevice = {
  tenantUrn: string
  hwDeviceId: string
  hardwareUrn: string
  created: string
  subscriptionExpiryDate: string
}

export type LogicalDevice = {
  tenantUrn: string
  logicalId: string
  schemaUrn: string
  created: string
}

export type DeviceAssignment = {
  hardwareDeviceId: string
  logicalDeviceId: string
  deviceSchemaUrn: string
  enabled: boolean
}

export type ApiRootResource = TypedRestResource<"root", SoftwareVersion>
export type HardwareResource<EXT extends object = object> = TypedRestResource<
  "hardware-device",
  HardwareDevice,
  EXT
>
export type LogicalDeviceResource = TypedRestResource<"logical-device", LogicalDevice>
export type HardwareDeviceAssignmentResource = TypedRestResource<
  "hardware-device-assignment",
  DeviceAssignment
>
export type ProvisioningApiDeviceResourceTypes =
  | HardwareResource
  | LogicalDeviceResource
  | HardwareDeviceAssignmentResource

export type ProvisioningApResourceTypes =
  | TypedRestResource<"self", object>
  | ApiRootResource
  | ErrorResponse
  | ProvisioningApiUserResourceTypes
  | ProvisioningApiDeviceResourceTypes

export type ProvisioningApCollectionTypes =
  | TypedRestResource<"roles-collection", RestFeed>
  | TypedRestResource<"tenant.member-collection", RestFeed>
  | TypedRestResource<"user.role-collection", RestFeed>
  | TypedRestResource<"hardware-device-collection", RestFeed>
  | TypedRestResource<"logical-device-collection", RestFeed>
  | TypedRestResource<"hardware-device-assignment-collection", RestFeed>

export const ROOT_LINK: RestLink = { rel: "root", uri: "root" }
export const CURRENT_USER_LINK: RestLink = { rel: "currentUser", uri: "user" }
export const CURRENT_TENANT_LINK: RestLink = { rel: "tenant", uri: "tenant" }

export function isRestFeed(data: unknown): data is RestFeed {
  const maybeFeed = data as Partial<RestFeed>
  if (!maybeFeed) return false
  return maybeFeed.type === "collection"
}

export function isRestResource(data: unknown): data is RestResource {
  const maybeFeed = data as Partial<RestResource>
  if (!maybeFeed) return false
  return maybeFeed.type !== "collection"
}

export function resourceSelfUri(response: BaseRestResource): string {
  const selfLink = response.relations.find((x) => x.rel === "self")
  if (!selfLink) throw new Error("Rest resource missing Self Link")
  return selfLink.uri
}

export function resourceRelation(
  resource: ProvisioningApResourceTypes | RestFeed,
  relation: RelationType,
): RestLink {
  const link = resource.relations.find((x) => x.rel === relation)
  if (!link)
    throw new Error(
      `Rest resource type:${resource.type} missing Link ${relation}, id:${resource.relations.find((x) => x.rel === "self")?.uri}`,
    )
  return link
}

export function hasResourceRelation(
  resource: ProvisioningApResourceTypes | RestFeed,
  relation: RelationType,
): boolean {
  return resource.relations.some((x) => x.rel === relation)
}

export function makeIdFromUri(uri: string) {
  return uri.split("/").reverse()[0]
}

export function makeIdFromUrn(urn: string) {
  return urn.split(":").reverse()[0]
}

export function isUserResource(
  resource: ProvisioningApResourceTypes | RestFeed | null,
): resource is UserResource {
  return resource && resource.type === "user" ? true : false
}
export function isRoleResource(
  resource: ProvisioningApResourceTypes | RestFeed | null,
): resource is RoleResource {
  return resource && resource.type === "role" ? true : false
}

export function isUserProfileResource(resource?: ProvisioningApResourceTypes): resource is ProfileResource {
  return resource && resource.type === "user.profile" ? true : false
}

function makeRoleUrl(id: UserRoleName) {
  return `role/${id}`
}

//**************** Permissions *******************
export function canEditRole(
  currentUser: UserInformation,
  userToEdit: UserResource,
  userRoleToEdit: RoleResource,
): boolean {
  const userId = resourceSelfUri(userToEdit)
  const currentUserId = resourceSelfUri(currentUser.user)

  const roleUri = resourceSelfUri(userRoleToEdit)
  // sas-owner cannot be changed
  if (roleUri === makeRoleUrl("sas-owner")) return false

  // Cannot edit self
  if (currentUserId === userId) return false

  // Owner can add and remove other owners
  if (roleUri === makeRoleUrl("owner")) return userIsInRole(currentUser.roles, "owner")
  if (roleUri === makeRoleUrl("user-admin")) return userIsInRole(currentUser.roles, "owner")
  if (roleUri === makeRoleUrl("device-admin")) return userIsInRole(currentUser.roles, "owner")

  throw new Error(`canEditRole, Unhandeled Role Types: ${userRoleToEdit.properties.name}`)
}

function userIsInRole(activeUserRoles: Array<RoleResource>, role: UserRoleName): boolean {
  const roleToCheck = makeRoleUrl(role)
  return activeUserRoles.some((r) => resourceSelfUri(r) === roleToCheck)
}

/** Manipulate roles on users */
export interface AddUserRoleRequest {
  add: Array<string>
  remove: Array<string>
}

export interface UpdateUserProfileRequest {
  updates: Partial<UserProfile>
}

export interface TransferHardwareDeviceRequest {
  fromHardwareDeviceId: string | undefined
  logicalDeviceId: string
}
