import { Box, Button, Popover } from "@mui/material"
import * as React from "react"

type ComponentProps = {
  renderPopOver: () => JSX.Element
}

const MouseOverPopover: React.FC<React.PropsWithChildren<ComponentProps>> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <Box>
      <Button aria-describedby={id} onClick={handlePopoverOpen}>
        {props.children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{ paper: { sx: { borderRadius: 5, backgroundColor: "#f8f8f0" } } }}
      >
        {props.renderPopOver()}
      </Popover>
    </Box>
  )
}

export const PopOvercomponent = MouseOverPopover
