import { ComponentType, LazyExoticComponent, lazy } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyLoad<T extends ComponentType<any>>(
  importStatement: () => Promise<Record<string | "default", T>>,
  nameExported: string = "default",
  /** Experimental */
  // higherOrderFunction: (component: T) => T | ComponentClass = (c) => c,
): LazyExoticComponent<T> {
  return lazy(() => {
    return importStatement().then((module) => {
      const exportObject = module[nameExported]
      if (!exportObject) throw new Error(`Lazy Load: Component:${nameExported} from ${module?.name}`)
      return { default: exportObject }
    })
  })
}
