import { Point } from "geojson"

export interface GeoLocation {
  lat: number
  lon: number
}
export interface GeoJsonPoint {
  type: "Point"
  coordinates: Array<number>
}

const Earth_Radius_Meters = 6371 * 1000

function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180.0
}

function radiansToDegrees(radians: number): number {
  let deg = ((radians * 180.0) / Math.PI) % 360
  while (deg < 0) deg += 360
  return deg
}

export function distanceInMeters(location1: GeoLocation, location2: GeoLocation): number {
  const lat1Rad = degreesToRadians(location1.lat)
  const lat2Rad = degreesToRadians(location2.lat)

  const dLat = degreesToRadians(location2.lat - location1.lat)
  const dLon = degreesToRadians(location2.lon - location1.lon)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return Earth_Radius_Meters * c
}

export function bearingBetweenPointsDegrees(from: GeoLocation, to: GeoLocation): number {
  const dLon = degreesToRadians(to.lon - from.lon)

  const φ1 = degreesToRadians(from.lat)
  const φ2 = degreesToRadians(to.lat)

  const y = Math.sin(dLon) * Math.cos(φ2)
  const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(dLon)

  const brng = Math.atan2(y, x)

  return radiansToDegrees(brng)
}

export function makeGeoLocationFromPoint(point?: Point): GeoLocation | undefined {
  if (!point) return undefined
  return { lat: point.coordinates[1], lon: point.coordinates[0] }
}

export function makePointFromGeoLocation(location?: GeoLocation): Point | undefined {
  if (!location) return undefined
  return {
    type: "Point",
    coordinates: [location.lon, location.lat],
  }
}

// export function formatLocation(geoLocation: GeoLocation | undefined): string | undefined {
//   return geoLocation ? `(Lat:${geoLocation.lat.toFixed(4)}, Lon:${geoLocation.lon.toFixed(4)})` : undefined
// }

export function formatGeoJsonPoint(geoLocation: GeoJsonPoint | undefined): string | undefined {
  if (!geoLocation) return undefined

  if (geoLocation.coordinates.length === 3) {
    return `(Lat:${geoLocation.coordinates[1].toFixed(4)}, Lon:${geoLocation.coordinates[0].toFixed(
      4,
    )}, alt:${geoLocation.coordinates[2].toFixed(1)})`
  }

  return `(Lat:${geoLocation.coordinates[1].toFixed(4)}, Lon:${geoLocation.coordinates[0].toFixed(4)})`
}

export function pointEquals(p1?: GeoJsonPoint, p2?: GeoJsonPoint): boolean {
  return (
    isValidGeoJsonPoint(p1) &&
    isValidGeoJsonPoint(p2) &&
    p1.coordinates[0] === p2.coordinates[0] &&
    p1.coordinates[1] === p2.coordinates[1]
  )
}

export function isValidGeoJsonPoint(point?: GeoJsonPoint): point is GeoJsonPoint {
  if (!point) return false
  if (point.type !== "Point") return false
  if (!point.coordinates || point.coordinates.length < 2) return false
  if (point.coordinates[0] === 0 && point.coordinates[1] === 0) return false

  return true
}
