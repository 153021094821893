export enum PolicyNames {
  SignIn = "B2C_1A_Custom_Signin_V2",
  SignUpSignIn = "B2C_1A_Custom_Signup_Signin_V2",

  PasswordReset = "B2C_1A_PasswordReset",
  ProfileEdit = "B2C_1A_ProfileEdit",
  ForcedPasswordReset = "B2C_1A_SignUp_SignIn_ForcePasswordReset",
}

export interface DomainConfiguration {
  clientId: string
  signInPolicyName: string

  /** Tenant domain name */
  authorityDomainName: string
  baseURL: string
  isLocal: boolean
  nodeEnv: "development" | "production"
}

export function makeApplicationConfig(): DomainConfiguration {
  const nodeEnv = (process.env.NODE_ENV ?? "production") ? "production" : "development"
  const domainConfigRaw: Partial<DomainConfiguration> = {
    signInPolicyName: PolicyNames.ForcedPasswordReset,
    clientId: process.env.REACT_APP_B2C_CLIENTID,
    authorityDomainName: process.env.REACT_APP_B2C_DOMAIN,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    isLocal: /localhost/.test(process.env.REACT_APP_API_BASE_URL ?? ""),
    nodeEnv,
  }

  return validateConfiguration(domainConfigRaw)
}

function validateConfiguration(rawConfig: Partial<DomainConfiguration>): DomainConfiguration {
  if (
    !rawConfig ||
    !rawConfig.authorityDomainName ||
    !rawConfig.signInPolicyName ||
    !rawConfig.clientId ||
    !rawConfig.baseURL ||
    !(rawConfig.isLocal === true || rawConfig.isLocal === false)
  ) {
    console.error(process.env, rawConfig)
    throw new Error(
      `Required MSAL Configurations are missing, check that environment varaibles set ${JSON.stringify(
        rawConfig,
      )}`,
    )
  }

  console.log(rawConfig)
  return rawConfig as DomainConfiguration
}
