import blue from "@mui/material/colors/blue"
import indigo from "@mui/material/colors/indigo"
import { createTheme } from "@mui/material/styles"
// https://bareynol.github.io/mui-theme-creator/
const Pollin8Theme = createTheme({
  palette: {
    primary: {
      main: indigo[900],
    },
    secondary: {
      main: blue[50],
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "2px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "2px",
          // [theme.breakpoints.up("md")]: {
          //   paddingTop: theme.spacing(6),
          // },
        },
      },
    },
    // MuiToggleButton: {
    //   styleOverrides: {
    //     selected: {
    //       backgroundColor: red[50],
    //       color: red[50],
    //     },
    //   },
    // },
  },
})

export default Pollin8Theme
