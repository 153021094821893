import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import React, { useMemo } from "react"
import { ProvisioningApiClient, makeProvisioningClient } from "../api/provisioning/provisioningClient"
import { ClientApi, makeGraphQLApi } from "../api/tenantQL/ClientApi"

export interface ClientApiContext {
  graphQLApi?: ClientApi
  provisioningApi?: ProvisioningApiClient
}

const clientApiContext = React.createContext<ClientApiContext>({
  graphQLApi: undefined,
  provisioningApi: undefined,
})

export const ClientApiContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { instance, inProgress } = useMsal()
  const apiContext = useMemo<ClientApiContext>(() => {
    return inProgress === InteractionStatus.None
      ? {
          graphQLApi: makeGraphQLApi(instance),
          provisioningApi: makeProvisioningClient(instance),
        }
      : {
          graphQLApi: undefined,
          provisioningApi: undefined,
        }
  }, [instance, inProgress])

  return <clientApiContext.Provider value={apiContext}>{props.children}</clientApiContext.Provider>
}

export function useClientApiContext(): ClientApiContext {
  return React.useContext(clientApiContext)
}
