import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import { Box, Button, Container, SxProps, Theme, Typography } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Unstable_Grid2"
import { Image } from "mui-image"
import React from "react"
import { HeaderAppBarComponent } from "../components/HeaderAppBar"
import logo from "../img/pollin8.svg"
import { SOFTWARE_VERSION } from "../version"

interface OwnProps {
  signInAction: () => void
}

type Props = OwnProps

const SignIn: React.FC<Props> = (props) => {
  const { signInAction } = props
  const { inProgress } = useMsal()

  // Box do used to have `clone`
  return (
    <Container maxWidth="sm" sx={classes.root}>
      <HeaderAppBarComponent showAlertFilterIcon={false}>
        <span>{"Pollin8 Track"}</span>
      </HeaderAppBarComponent>

      <Box sx={classes.boxStyle}>
        <Container maxWidth="xs">
          <Image src={logo} alt="Pollin8 Logo" sx={classes.logo} />
        </Container>
        <Grid container spacing={2} xs={12} wrap="nowrap" sx={classes.titleGrid}>
          <Grid>
            <Box>
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <LockOpenIcon />
              </Avatar>
            </Box>
          </Grid>
          <Grid>
            <Typography variant="h6">Welcome to Pollin8 Track!</Typography>
            <Typography variant="body2">
              Click on the button below to sign in with your organisation details.
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} sx={classes.buttonGrid}>
          <Grid>
            {
              // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
              inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect ? (
                <Button
                  sx={classes.signInButton}
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={() => signInAction()}
                >
                  Sign In
                </Button>
              ) : null
            }
          </Grid>
        </Grid>
        <Grid container xs={12} sx={classes.buttonGrid}>
          <Grid>
            <Typography sx={classes.versionText} variant="caption">
              Software version: {SOFTWARE_VERSION.softwareVersion}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

const classes: Record<string, SxProps<Theme>> = {
  root: {
    padding: (theme) => ({ sm: theme.spacing(0) }),
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  boxStyle: {
    justifyContent: "center",
    alignItems: "center",
  },
  titleGrid: {
    marginTop: (theme) => theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  buttonGrid: {
    marginTop: (theme) => theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
  },
  signInButton: {},
  logo: {
    marginTop: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
  },
  versionText: {
    color: (theme) => theme.palette.secondary.dark,
  },
}

export const SignInComponent = SignIn
