import React from "react"
import { connect } from "react-redux"
import { SingleDeviceResponse } from "../../api/tenantQL/TenantQL"
import { DeviceReducerState } from "../../redux/reducers/deviceReducer"
import { RootState } from "../../redux/store/rootStore"
import { lazyLoad } from "../lazyLoad"
import { useModalContext } from "./DeviceModalContextProvider"
import { DialogComponent } from "./DialogComponent"
// import { DeviceDetailComponent } from "../DeviceDetailComponent/DeviceDetailComponent"

interface StateProps {
  selectedDevice?: SingleDeviceResponse
}

type ComponentProps = StateProps
const DeviceDetailComponent = lazyLoad(
  () => import("../DeviceDetailComponent/DeviceDetailComponent"),
  "DeviceDetailComponent",
)

const GlobalDeviceModal: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { selectedDevice } = props
  const { deviceDetail } = useModalContext()

  return (
    <DialogComponent title={"Device Detail"} isOpen={deviceDetail.isOpen} onClose={deviceDetail.close}>
      {selectedDevice && deviceDetail.isOpen ? (
        <DeviceDetailComponent selectedDevice={selectedDevice} close={deviceDetail.close} />
      ) : (
        "No Device Currently Selected"
      )}
    </DialogComponent>
  )
}

function mapStatetoProps(state: RootState): StateProps {
  const deviceReducer: DeviceReducerState = state.devices
  return {
    selectedDevice: deviceReducer.currentDevice,
  }
}

export const DeviceDetailModalComponent = connect(mapStatetoProps)(GlobalDeviceModal)
