import React, { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import {
  ProfileResource,
  Role,
  RoleResource,
  Tenant,
  TenantResource,
  User,
  UserProfile,
  UserResource,
} from "../../api/provisioning/clientTypes"
import { ProvisioningApiClient } from "../../api/provisioning/provisioningClient"
import { ClientApi } from "../../api/tenantQL/ClientApi"
import { UserInformation } from "../../pages/UserManagementPage/UserManagementPage"
import { AuthReducer } from "../../redux/reducers/authReducer"
import { TenantReducer } from "../../redux/reducers/tenantReducer"
import {
  PartialUserInformation,
  loadCurrentUserInfo,
} from "../../redux/reducers/userManagementReducer/userManagmentReducer"
import { AppDispatch, RootState } from "../../redux/store/rootStore"
import { LoadingSpinner } from "../LoadingSpinnerComponent"
import { useClientApiContext } from "../RestApiClientProvider"
import { UserRoleName } from "@pollin8/contracts"

interface UserInfoContext {
  tenant?: Tenant
  /** @deprecated */
  userProperties?: {
    user: User
    userProfile: UserProfile
    userRoles: Array<Role>
  }
  userInfoResource?: UserInformation
}

export interface UserResourceInformation {
  user: UserResource
  profile: ProfileResource
  roles: Array<RoleResource>
}

const userInfoContext = React.createContext<UserInfoContext>({
  tenant: undefined,
  userInfoResource: undefined,
  userProperties: undefined,
})
interface StateProps {
  pollin8Token?: string
  graphToken?: string
  userProfileFetchRequired: boolean
  gqlApiIsLoading: boolean
  currentTenant?: TenantResource
  currentUser?: PartialUserInformation
}
interface DispatchProps {
  graphQlApiProfile(api: ClientApi): void
  graphQlApiTenant(api: ClientApi): void
  fetchCurrentUserInfo(api: ProvisioningApiClient): void
}

type ComponentProps = StateProps & DispatchProps & React.PropsWithChildren

const UserInfoProvider: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { graphQLApi, provisioningApi } = useClientApiContext()
  const {
    gqlApiIsLoading,
    userProfileFetchRequired,
    currentTenant,
    currentUser,
    graphQlApiProfile,
    graphQlApiTenant,
    fetchCurrentUserInfo,
  } = props

  useEffect(() => {
    console.log("UserInfoProvider - fetchCurrentUserInfo")
    if (provisioningApi) fetchCurrentUserInfo(provisioningApi)
  }, [provisioningApi, fetchCurrentUserInfo])

  useEffect(() => {
    console.log("UserInfoProvider - graphQlApiProfile")
    if (graphQLApi) {
      if (gqlApiIsLoading === false && userProfileFetchRequired === true) {
        graphQlApiProfile(graphQLApi)
        graphQlApiTenant(graphQLApi)
      }
    }
  }, [gqlApiIsLoading, userProfileFetchRequired, graphQLApi, graphQlApiProfile, graphQlApiTenant])

  const userContext = useMemo<UserInfoContext>(() => {
    console.log("UserInfoProvider - memo")
    const context: UserInfoContext =
      currentTenant && isUserInformation(currentUser)
        ? {
            tenant: currentTenant.properties,
            userProperties: {
              user: currentUser.user.properties,
              userProfile: currentUser.profile.properties,
              userRoles: currentUser.roles.map((x) => x.properties),
            },
            userInfoResource: currentUser,
          }
        : { tenant: undefined, userInfoResource: undefined, userProperties: undefined }

    return context
  }, [currentTenant, currentUser])

  const children =
    userContext.tenant && userContext.userInfoResource ? (
      props.children
    ) : (
      <LoadingSpinner isLoading={true} messageText="Loading User Data..." />
    )
  return <userInfoContext.Provider value={userContext}>{children}</userInfoContext.Provider>
}

function mapStateToProps(state: RootState): StateProps {
  const { auth, userManagement } = state
  const { currentTenant, currentUser } = userManagement

  const props: StateProps = {
    userProfileFetchRequired: auth.userProfile ? false : true,
    gqlApiIsLoading: auth.isLoading,
    // contextData: context,
    currentTenant,
    currentUser,
  }

  return props
}

function mapDispatchProps(dispatch: AppDispatch): DispatchProps {
  return {
    graphQlApiProfile: (api: ClientApi) => {
      dispatch(AuthReducer.makeFetchUserProfile(api.query.getUserProfile()))
    },
    graphQlApiTenant: (api: ClientApi) => {
      dispatch(TenantReducer.fetchTenant(api.query.getTenant()))
    },
    fetchCurrentUserInfo: (api: ProvisioningApiClient) => {
      dispatch(loadCurrentUserInfo({ api }))
    },
  }
}

export const UserInfoContextProvider = connect(mapStateToProps, mapDispatchProps)(UserInfoProvider)

export function useUserInfoContext(): UserInfoContext {
  return React.useContext(userInfoContext)
}

function isUserInformation(data: unknown): data is UserInformation {
  const maybe = data as Partial<UserInformation>
  return maybe && maybe.user && maybe.profile && maybe.roles ? true : false
}

export function isInRole(user: UserInformation | undefined, roleName: UserRoleName): boolean {
  return user?.roles.some((role) => role.properties.id === roleName) ?? false
}
