import { ResourceLoadOptions, UserManagementReducer, applyResource } from "./userManagmentReducer"
import { ErrorResponse, ProvisioningApResourceTypes, isRestFeed } from "../../../api/provisioning/clientTypes"
import { ProvisioningApiClient } from "../../../api/provisioning/provisioningClient"
import { RestFeed, RestLink } from "../../../api/provisioning/restTypes"

export async function loadResourceRaw(
  arg: {
    api: ProvisioningApiClient
    link: RestLink
    options?: Partial<ResourceLoadOptions>
  },
  state: UserManagementReducer,
  dispatch,
) {
  const { api, link } = arg
  const userManagement = state

  const defaultResourceLoadOptions: ResourceLoadOptions = {
    forceReload: false,
    loadResourceTypes: [],
    context: {},
  }
  const opt = {
    ...defaultResourceLoadOptions,
    ...arg.options,
  }

  const findResource = (state: UserManagementReducer, link: RestLink) =>
    state.allResources[link.uri] || state.allFeeds[link.uri]

  // Existing resource Resource is already loaded
  const existingResource = findResource(userManagement, link)
  if (!existingResource || opt.forceReload === true) {
    // Check for errors
    console.info(`Load ResourceRaw: ${link.rel}`, { link })
    const response = await api.loadResource(link)
    if (response.status >= 400) {
      console.error("Load Error", { link, status: response.status })
      const errorResponse: ErrorResponse = {
        type: "error",
        properties: {
          statusCode: response.status,
          description: response.bodyText,
        },
        relations: [{ rel: "self", uri: link.uri }],
      }
      userManagement.allResources[link.uri] = errorResponse
      return errorResponse
    }

    // Cycle detection - After load
    opt.context[link.uri] = recordVisit(opt.context[link.uri], true)

    const { data } = response
    await applyResource(dispatch, data)

    // Do we need to wait for this ?
    await loadChildrenRelationsRaw(api, data, opt, state, dispatch)
    return data
  } else {
    // Cycle detection - After load
    opt.context[link.uri] = recordVisit(opt.context[link.uri], false)
    await loadChildrenRelationsRaw(api, existingResource, opt, state, dispatch)
    return existingResource
  }
}

async function loadChildrenRelationsRaw(
  api: ProvisioningApiClient,
  data: ProvisioningApResourceTypes | RestFeed,
  options: ResourceLoadOptions,
  state: UserManagementReducer,
  dispatch,
) {
  // Feed or Resource
  if (isRestFeed(data)) {
    // Load children in order of resource links
    await Promise.all(
      data.items
        .map((x) => x.id)
        // Cycle detection
        .filter((x) => (options.context[x.uri] ? false : true))
        .filter((x) => shouldFollowChildRelation(options, x))
        // .map((x) => dispatch(loadResource({ api, link: x, options }))),
        .map((x) => loadResourceRaw({ api, link: x, options }, state, dispatch)),
    )
  } else {
    // Load children in order of resource links
    await Promise.all(
      data.relations
        // Cycle detection
        .filter((x) => (options.context[x.uri] ? false : true))
        .filter((x) => shouldFollowChildRelation(options, x))
        // Loads all resources async, non deterministic ordering
        // .map(async (x) => await dispatch(loadResource({ api, link: x, options }))),
        .map(async (x) => await loadResourceRaw({ api, link: x, options }, state, dispatch)),
    )
  }
}

function shouldFollowChildRelation(options: ResourceLoadOptions, link: RestLink): boolean {
  return (
    link.rel !== "self" &&
    (options.loadResourceTypes.includes("*") || options.loadResourceTypes.includes(link.rel))
  )
  // (opt.forceReload === true || !isResourceLoaded(state, link))
}

// export function shouldFollowChildOption(links: Array<RestLink>, option: RelationType | "*"): boolean {
//   return links.some((link) => link.rel !== "self" && (option === "*" || option === link.rel))
// }

function recordVisit(
  visit: { count: number; loaded: boolean } | undefined,
  wasLoaded: boolean,
): {
  count: number
  loaded: boolean
} {
  return visit
    ? { count: visit.count + 1, loaded: visit.loaded || wasLoaded }
    : { count: 1, loaded: wasLoaded }
}
