import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Theme } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { makeStyles } from "@mui/styles"
import * as React from "react"
import { PropsWithChildren } from "react"
import { connect } from "react-redux"
import { RootState } from "../../redux/store/rootStore"

interface OwnProps {
  title: string
  isOpen: boolean
  onClose: () => void
  showCloseButton?: boolean
  children?: React.ReactNode
}

interface StateProps {
  title: string
  open: boolean
  onHandleClose: () => void
  showCloseButton: boolean
}

type ComponentProps = StateProps

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<unknown, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const DeviceDetailDialog: React.FC<PropsWithChildren<ComponentProps>> = (props) => {
  const { children, title, open, onHandleClose, showCloseButton } = props
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth={"md"}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ className: classes.dialogMain }}
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        {showCloseButton ? (
          <Button onClick={onHandleClose} color="primary" variant="contained">
            Close
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

function mapStatetoProps(state: RootState, ownProps: OwnProps): StateProps {
  return {
    title: ownProps.title,
    open: ownProps.isOpen,
    onHandleClose: ownProps.onClose,
    showCloseButton: ownProps.showCloseButton ?? true,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dialogMain: {
    margin: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(8),
    },
  },
  dialogContent: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4),
    },
  },
  dialogActions: {
    justifyContent: "right",
  },
}))

export const DialogComponent = connect(mapStatetoProps)(DeviceDetailDialog)
