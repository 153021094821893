// Create the main myMSALObj instance

import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser"
import { makeApplicationConfig } from "./ApplicationConfiguration"
import { MsalConfig } from "./authConfig"
import { resetPassword, setAccount, signOut } from "./authUtil"

const domainConfig = makeApplicationConfig()
const msalConfig = MsalConfig.makeMsalConfig(domainConfig)
// configuration parameters are located at authConfig.js
export const myMSALObj: IPublicClientApplication = new PublicClientApplication(msalConfig)
myMSALObj.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!myMSALObj.getActiveAccount() && myMSALObj.getAllAccounts().length > 0) {
    selectAccount()
  }

  // Optional - This will update account state if a user signs in from another tab or window
  myMSALObj.enableAccountStorageEvents()

  // in case of page refresh
  myMSALObj.addEventCallback(authStateChangeCallback)
  myMSALObj
    .handleRedirectPromise()
    .then((response) => {
      if (response) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        const policy: string | undefined = response.idTokenClaims["tfp"] || response.idTokenClaims["acr"]
        if (policy?.toLocaleUpperCase() === domainConfig.signInPolicyName.toUpperCase()) {
          handleResponse(response)
        }
      }
    })
    .catch((error) => {
      console.log(error)
    })
})

async function handleResponse(response?: AuthenticationResult | null): Promise<void> {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response) setAccount(myMSALObj, response.account)
  else selectAccount()
}

function selectAccount(): void {
  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts: Array<AccountInfo> = myMSALObj.getAllAccounts()

  if (currentAccounts.length < 1) {
    return
  }

  if (currentAccounts.length === 1) {
    setAccount(myMSALObj, currentAccounts[0])
    return
  }

  /**
   * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
   * is cached as a new account, which results in more than one account in the cache. Here we make
   * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
   * as this is the default flow the user initially signed-in with.
   */
  const accounts = currentAccounts.filter(
    (account) =>
      account.homeAccountId.toUpperCase().includes(domainConfig.signInPolicyName.toUpperCase()) &&
      account.idTokenClaims?.iss?.toUpperCase().includes(domainConfig.authorityDomainName.toUpperCase()) &&
      account.idTokenClaims.aud === msalConfig.auth.clientId,
  )
  if (accounts.length === 1) {
    setAccount(myMSALObj, accounts[0])
    return
  }

  if (accounts.length > 1) {
    // localAccountId identifies the entity for which the token asserts information.
    if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
      // All accounts belong to the same user
      setAccount(myMSALObj, accounts[0])
    } else {
      // Multiple users detected. Logout all to be safe.
      signOut(myMSALObj)
    }
  }
}

function authStateChangeCallback(event: EventMessage) {
  switch (event.eventType) {
    case EventType.LOGIN_SUCCESS: {
      if (event.payload) {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        const payload = event.payload as AuthenticationResult
        myMSALObj.setActiveAccount(payload.account)
      }
      break
    }

    case EventType.LOGIN_START:
    case EventType.ACQUIRE_TOKEN_START:
    case EventType.ACQUIRE_TOKEN_SUCCESS:
    case EventType.ACQUIRE_TOKEN_NETWORK_START:
    case EventType.SSO_SILENT_START:
    case EventType.SSO_SILENT_SUCCESS:
    case EventType.HANDLE_REDIRECT_START:
    case EventType.HANDLE_REDIRECT_END:
    case EventType.LOGOUT_START:
    case EventType.LOGOUT_SUCCESS:
      // eslint-disable-next-line no-console
      // console.info({ aim: "authStateChangeCallback", message: event })
      break

    case EventType.LOGIN_FAILURE:
    case EventType.ACQUIRE_TOKEN_FAILURE:
    case EventType.SSO_SILENT_FAILURE:
    case EventType.LOGOUT_FAILURE:
      // eslint-disable-next-line no-console
      console.error({ aim: "authStateChangeCallback", message: event })
      break
  }
  if (event.interactionType === InteractionType.Redirect) {
    if (event.eventType === EventType.LOGIN_FAILURE) {
      if (
        event.error &&
        "errorMessage" in event.error &&
        event.error.errorMessage &&
        event.error.errorMessage.indexOf("AADB2C90118") > -1
      ) {
        resetPassword(myMSALObj)
      }
    }
  }
}
