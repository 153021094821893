// import { rootReducer } from "./rootReducer"

import { configureStore } from "@reduxjs/toolkit"
// import thunk from "redux-thunk"
import { AuthReducer } from "../reducers/authReducer"
import { deviceHistoryReducer } from "../reducers/deiviceHistoryReducer"
import { DeviceReducer } from "../reducers/deviceReducer"
import { moveReducerSlice } from "../reducers/moveDeviceReducer"
import { TenantReducer } from "../reducers/tenantReducer"
import { userManagementSlice } from "../reducers/userManagementReducer/userManagmentReducer"
import { UserReducer } from "../reducers/userReducer"

export const rootStore = configureStore({
  reducer: {
    devices: DeviceReducer.reducer,
    deviceHistory: deviceHistoryReducer.reducer,
    tenant: TenantReducer.reducer,
    auth: AuthReducer.reducer,
    user: UserReducer.reducer,
    userManagement: userManagementSlice.reducer,
    moveDevice: moveReducerSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk).concat(logger),
  middleware: (gdf) => gdf({ immutableCheck: false }),
  devTools: true,
})

export type AppDispatch = typeof rootStore.dispatch
export type RootState = ReturnType<typeof rootStore.getState>
