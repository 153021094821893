import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface UserReducerState {
  position?: {
    lat: number
    lon: number
    altitude: number
  }
}

const initialState: UserReducerState = {
  position: undefined,
}

const reducerSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setPosition(state, action: PayloadAction<{ lat: number; lon: number; altitude?: number }>) {
      //const positionTransposed = { lat: action.payload.lon, lon: action.payload.lat }
      state.position = {
        altitude: 0,
        ...action.payload,
      }
    },
  },
})

export const UserReducer = {
  reducer: reducerSlice.reducer,
  setPosition: reducerSlice.actions.setPosition,
}
