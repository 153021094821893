import React, { useContext } from "react"

export interface ModalContextData {
  isOpen: boolean
  close: () => void
  open: () => void
}

export interface DeviceModalContextData {
  deviceDetail: ModalContextData
  deviceFilter: ModalContextData
}

const empty: ModalContextData = {
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  open: () => {},

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  close: () => {},
}

const initialState: DeviceModalContextData = { deviceDetail: empty, deviceFilter: empty }
const deviceModalContext = React.createContext<DeviceModalContextData>(initialState)

export const ModalContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [isOpenDeviceModal, setOpenDeviceModal] = React.useState(false)
  const [isOpenFilterModal, setOpenFilterModal] = React.useState(false)

  const modalContextState: DeviceModalContextData = {
    deviceDetail: {
      isOpen: isOpenDeviceModal,
      open: () => setOpenDeviceModal(true),
      close: () => setOpenDeviceModal(false),
    },
    deviceFilter: {
      isOpen: isOpenFilterModal,
      open: () => setOpenFilterModal(true),
      close: () => setOpenFilterModal(false),
    },
  }
  return <deviceModalContext.Provider value={modalContextState}>{props.children}</deviceModalContext.Provider>
}

export function useModalContext(): DeviceModalContextData {
  return useContext(deviceModalContext)
}
