import { SxProps, Theme } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import React from "react"

type ComponentProps = {
  displayName: string
  imageUri?: string
}

export const UserAvatarComponent: React.FC<ComponentProps> = (props) => {
  return props.imageUri ? (
    <Avatar alt={props.displayName} src={props.imageUri} />
  ) : (
    <Avatar alt={props.displayName} {...stringAvatar(props.displayName)} />
  )
}

function stringToColor(string: string) {
  let hash = 0

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function makeInitials(name: string) {
  const hasFirstandLast = name.split(" ", 2).length == 2

  if (hasFirstandLast) {
    const nameSplit = name.split(" ")
    const firstInitial = nameSplit[0][0]
    const secondInitial = nameSplit[1][0]
    return `${firstInitial}${secondInitial}`.toUpperCase()
  } else {
    return `${name[0]}${name[1]}`.toUpperCase()
  }
}

function stringAvatar(name: string) {
  const sx: SxProps<Theme> = {
    bgcolor: stringToColor(name),
  }

  return {
    sx,
    children: makeInitials(name),
  }
}
