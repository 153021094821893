import { withAITracking } from "@microsoft/applicationinsights-react-js"
import React from "react"
import { useRouteError } from "react-router-dom"
import { reactAppInsightsPlugin } from "../../applicationInsights"

const Component: React.FC = () => {
  const error = useRouteError() as Error
  console.error(error)

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>ErrorType: {error.name}</i>
      </p>
      <p>
        <i>Message: {error.message}</i>
      </p>

      <p>
        <i>Callstack: {error.stack}</i>
      </p>
    </div>
  )
}

export const ErrorPage = withAITracking(reactAppInsightsPlugin, Component, "ErrorPage")
