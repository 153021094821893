/* eslint-disable no-console */

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

// https://www.deanhume.com/displaying-a-new-version-available-progressive-web-app/
// Testing in browser
// https://medium.com/dev-channel/testing-service-workers-318d7b016b19
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

type Config = {
  /** Update has completed */
  onSuccess?: (registration: ServiceWorkerRegistration) => void

  /** Update available */
  onUpdate?: (registration: ServiceWorkerRegistration) => void

  /** Called when ever a registration completes */
  onRegistrationActive?: (registration: ServiceWorkerRegistration) => void
}

// Blair new code
let refreshing = false

export function register(config?: Config): void {
  // const PUBLIC_URL = "/"
  const PUBLIC_URL = process.env.PUBLIC_URL
  if (process.env.NODE_ENV !== "production") {
    console.log(
      "Non Production environment.",
      "Service worker will not be registered in env:",
      process.env.NODE_ENV,
    )
    return
  }
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      console.warn("Skipping service worker registration, different domain")
      return
    }

    window.addEventListener("load", () => {
      const swUrl = `${PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit https://cra.link/PWA",
          )
        })

        return
      }

      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config)
    })

    // Blair new code
    navigator.serviceWorker.addEventListener("controllerchange", function () {
      if (refreshing === true) return
      console.info("Service worker 'controllerchange' event. Reloading...")
      window.location.reload()
      refreshing = true
    })
  } else {
    console.warn("Service Worker: Not availabel on navigator")
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log("Service Worker : Registered")

      // If it is already active, e.g. not a new registration
      if (registration.active && registration.active.state === "activated") {
        if (config && config.onRegistrationActive) {
          config.onRegistrationActive(registration)
        }
      }

      registration.onupdatefound = () => {
        console.log("Serve Worker : Update found")
        const installingWorker = registration.installing
        if (installingWorker == null) {
          console.warn("The service worker is not installing. Is the test environment clean?")
          return
        }

        installingWorker.onstatechange = () => {
          console.log("Serve Worker : State Changed", installingWorker.state)
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://cra.link/PWA.",
              )

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use, by servie worker.")

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }

          if (installingWorker.state === "activated") {
            if (config && config.onRegistrationActive) {
              config.onRegistrationActive(registration)
            }
          }
        }
      }
    })
    .catch((error) => console.error("Error during service worker registration:", error))
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, { headers: { "Service-Worker": "script" } })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type")
      if (response.status === 404 || (contentType && contentType.indexOf("javascript") === -1)) {
        if (response.status === 404)
          console.warn("No service worker found. Probably a different app. Reload the page.")
        if (contentType && contentType.indexOf("javascript") === -1)
          console.warn("No service worker found. Invalid content type. Reload the page.")

        navigator.serviceWorker.ready
          .then((registration) => registration.unregister())
          .then(() => window.location.reload())
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch((e) => {
      console.error("No internet connection found. App service-worker is running in offline mode.", e)
    })
}

export function unregister(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => registration.unregister())
      .catch((error) => console.error("Un-Register worker error", error.message, error))
  }
}
