import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser"
import { getAccessToken, getToken } from "../authentication/authUtil"
import { ApiClientOptions } from "./httpClient/HttpClientTypes"
import { SoftwareVersion } from "../api/provisioning/clientTypes"

export function makeApiClientAuthOptionsFromMSAL(
  instance: IPublicClientApplication,
  requestOptions: SilentRequest,
): ApiClientOptions {
  const options: ApiClientOptions = {
    auth: {
      acquireToken: () => {
        return getAccessToken(instance, requestOptions).then((result) => (result ? result : undefined))
      },
    },
  }

  //cachedOptions = options;
  return options
}

export function makeApiNounceProvider(
  instance: IPublicClientApplication,
  requestOptions: SilentRequest,
  softwareVersion: string,
): () => Promise<string> {
  return () =>
    getToken(instance, requestOptions).then((x) =>
      x
        ? calculateStringHash(x.idTokenClaims["tenantUrn"] + x.idTokenClaims["name"] + softwareVersion)
        : "missing",
    )
}

function calculateStringHash(data: string): string {
  const hash = Array.from(data).reduce((hash, char) => 31 * hash + char.charCodeAt(0), 0) % 1000000
  return hash.toFixed(0).padStart(6, "0")
}
