import { ProvisioningApResourceTypes, resourceSelfUri } from "../../../api/provisioning/clientTypes"
import { RestCollectionRelationType, RestLink, RestResourceType } from "../../../api/provisioning/restTypes"
import { isDefined } from "../../../common/validation"
import { UserManagementReducer } from "."

export function getResource<T extends ProvisioningApResourceTypes = ProvisioningApResourceTypes>(
  state: UserManagementReducer,
  link: RestLink,
): T | undefined {
  const res = state.allResources[link.uri] ? state.allResources[link.uri] : undefined
  if (!res) return undefined
  if (res.type !== link.rel) throw new Error("getResource - Type miss match")
  return res as T
}

export function areSameResource<T extends ProvisioningApResourceTypes>(a: T, b: T): boolean {
  return resourceSelfUri(a) === resourceSelfUri(b)
}

export function findResource<T extends ProvisioningApResourceTypes = ProvisioningApResourceTypes>(
  state: UserManagementReducer,
  resource: ProvisioningApResourceTypes,
  resourceRelation: RestResourceType,
): T | undefined {
  const relationLink = resource.relations.find((x) => x.rel === resourceRelation)
  if (!relationLink) throw new Error("Rest resource missing Link " + resourceRelation)
  return state.allResources[relationLink.uri] as T
}

/** Finds a collection on a resource and loads the resources referenced by the collection */
export function findCollection<T extends ProvisioningApResourceTypes>(
  state: UserManagementReducer,
  resource: ProvisioningApResourceTypes | undefined,
  collectionRelation: RestCollectionRelationType,
): Array<T> {
  if (!resource) return []
  const relationLink = resource.relations.find((x) => x.rel === collectionRelation)
  if (!relationLink) throw new Error("Rest collection resource missing Link " + collectionRelation)

  const maybeCollection = state.allFeeds[relationLink.uri]
  const resourceCollection = maybeCollection
    ? maybeCollection.items.map((x) => state.allResources[x.id.uri])
    : []

  const collection = resourceCollection.filter(isDefined).filter((x) => x.type !== "error") as Array<T>
  return collection
}
