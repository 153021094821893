import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Point } from "geojson"
import { MutationResponseGraphType } from "../../api/tenantQL/ManagementApi.autogenerated"
import {
  isBinLevelResponse,
  isBoltV2Response,
  isDevice,
  isDualBatteryVoltageResponse,
  isLocationDevice,
  isOyster3BlueToothResponse,
  isOyster3Response,
  isOysterResponse,
  isPeopleCounterResponse,
  MaybeSingleDeviceResponse,
  SingleDeviceResponse,
} from "../../api/tenantQL/TenantQL"
import { distanceInMeters, GeoLocation } from "../../common/geometry"

const REDUCER_NAME = "graphql-device-data"
//The bounding box to limit the map view to. Format [West, South, East, North]
export const NewZealandBoundingBox = [165, -47.5, -179, -34] //All NZ

export interface FilterState {
  filter: DeviceFilterOptionsEnum
  location: Point | undefined
  deviceName: string | undefined
}

export interface DeviceReducerState {
  currentDevice?: SingleDeviceResponse
  allDevices: { [index: string]: SingleDeviceResponse }
  timestamp: number
  filteredDevices: {
    boundingBox: Array<number>
    devices: Array<SingleDeviceResponse>
  }

  searchQuery?: string
  deviceFilter: {
    filterState: FilterState
    description: string
  }
  errorMessage?: string
  counts: { [key: string]: number }
  loading: boolean
}

export enum DeviceFilterOptionsEnum {
  NotSet = "Not-Set",

  FromMe30km = "30KM",
  All = "All",
  Alert = "Alert",
  LowBattery = "Low Battery",
  LocationAlarm = "LocationAlarm",
  NotSeen = "NotSeen",
}

export const deviceFilterOptions: Array<{ id: DeviceFilterOptionsEnum; name: string }> = [
  { id: DeviceFilterOptionsEnum.FromMe30km, name: "Near Me (Within 30km)" },
  { id: DeviceFilterOptionsEnum.All, name: "All Devices" },
  { id: DeviceFilterOptionsEnum.Alert, name: "With Alerts" },
  { id: DeviceFilterOptionsEnum.LowBattery, name: "Low-Battery" },
  { id: DeviceFilterOptionsEnum.LocationAlarm, name: "Location Alarm" },
  { id: DeviceFilterOptionsEnum.NotSeen, name: "Not Seen" },
]

const initialState: DeviceReducerState = {
  currentDevice: undefined,
  allDevices: {},
  timestamp: 0,
  filteredDevices: {
    boundingBox: NewZealandBoundingBox,
    devices: [],
  },
  deviceFilter: {
    filterState: {
      filter: DeviceFilterOptionsEnum.NotSet,
      location: undefined,
      deviceName: undefined,
    },
    description: "Not Set",
  },
  loading: false,

  errorMessage: "",
  counts: {},
}

function makeThunkAction<T>(): (promise: Promise<T>, thunkApi: unknown) => Promise<T> {
  return (promise: Promise<T>): Promise<T> => {
    return promise
  }
}

const findDeviceResponseThunkAction = async (promise: Promise<Array<SingleDeviceResponse>>) => {
  return await promise
}

function makeGeoLocation(point: Point): GeoLocation {
  return { lat: point.coordinates[1], lon: point.coordinates[0] }
}

function filterDevice(filter: FilterState, device: SingleDeviceResponse): boolean {
  if (filter.deviceName) {
    const nameFilter = filter.deviceName.trim().toLowerCase()
    const nameFilterMatch = device.name.toLowerCase().indexOf(nameFilter) >= 0

    const addressFilterMatch =
      isLocationDevice(device) && device.address
        ? device.address.toLowerCase().indexOf(nameFilter) >= 0
        : false

    if (!nameFilterMatch && !addressFilterMatch) {
      return false
    }
  }

  switch (filter.filter) {
    case DeviceFilterOptionsEnum.All:
      return true

    case DeviceFilterOptionsEnum.Alert:
      return device.alarms && device.alarms.some((x) => x.status === "Active" || x.status === "ACTIVE")
        ? true
        : false

    case DeviceFilterOptionsEnum.LocationAlarm:
      return device.alarms && device.alarms.length > 0 ? true : false

    case DeviceFilterOptionsEnum.NotSeen: {
      const lastSeenDate = Date.parse(device.timestamp)
      const ageMS = Date.now() - lastSeenDate

      // Not seen for 2 days
      return ageMS > 2 * 24 * 60 * 60 * 1000
    }

    case DeviceFilterOptionsEnum.LowBattery: {
      if (isPeopleCounterResponse(device)) {
        return device.batteryVoltage < 6.0
      }

      if (isOysterResponse(device)) {
        return device.batteryVoltage < 4.8
      }

      if (isBinLevelResponse(device)) {
        return device.batteryVoltage < 5.0
      }

      if (isDualBatteryVoltageResponse(device)) {
        return device.batteryVoltage < 5.0
      }

      if (isBoltV2Response(device)) {
        return device.vbat ? device.vbat < 0.0 : false
      }

      if (isOyster3Response(device)) {
        return device.vbat ? device.vbat < 4.8 : false
      }

      if (isOyster3BlueToothResponse(device)) {
        return device.vbat ? device.vbat < 4.8 : false
      }

      return false
    }

    case DeviceFilterOptionsEnum.FromMe30km: {
      if (!isLocationDevice(device)) return false
      if (!filter.location || !device.location) return false

      const deviceLocation = makeGeoLocation(device.location)
      const pointLocation = makeGeoLocation(filter.location)
      return distanceInMeters(deviceLocation, pointLocation) <= 30000
    }

    default:
      return false
  }
}

function updateSingleDevice(device: SingleDeviceResponse, devices?: Array<SingleDeviceResponse>): void {
  if (!devices) {
    return
  }
  const deviceStateIndex = devices.findIndex((x) => x.deviceUrn === device.deviceUrn)
  if (deviceStateIndex >= 0) {
    devices[deviceStateIndex] = device
  }
}

function updateCurrentDevice(
  device: SingleDeviceResponse,
  currentdevice?: SingleDeviceResponse,
): SingleDeviceResponse | undefined {
  return currentdevice && device.deviceUrn === currentdevice.deviceUrn ? device : currentdevice
}

function filterDevices(
  filter: Omit<FilterState, "description">,
  allDevices: { [index: string]: SingleDeviceResponse },
): Array<SingleDeviceResponse> {
  const filterdDeviceList = Object.keys(allDevices)
    .map((key) => allDevices[key])
    .filter((x) => filterDevice(filter, x))

  return sortDevices(filterdDeviceList)
}

function makeFilterCounts(allDevices: { [index: string]: SingleDeviceResponse }): { [key: string]: number } {
  const counts: { [key: string]: number } = {}
  counts[DeviceFilterOptionsEnum.Alert] = filterDevices(
    { filter: DeviceFilterOptionsEnum.Alert, location: undefined, deviceName: undefined },
    allDevices,
  ).length

  return counts
}

function sortDevices(devices: Array<SingleDeviceResponse>): Array<SingleDeviceResponse> {
  const notSet = "not-set"
  return devices.sort((a, b) => (a.name || notSet).localeCompare(b.name || notSet))
}

const additionalActions = {
  makeDeviceQueryAction: createAsyncThunk("device/query", findDeviceResponseThunkAction),
  reloadDeviceData: createAsyncThunk("device/reload", makeThunkAction<Array<SingleDeviceResponse>>()),
  makeCreateAlarmAction: createAsyncThunk(
    "device/alarm/create",
    makeThunkAction<MaybeSingleDeviceResponse>(),
  ),
  makeDeleteAlarmAction: createAsyncThunk(
    "device/alarm/delete",
    makeThunkAction<MaybeSingleDeviceResponse>(),
  ),
  replayEventsAction: createAsyncThunk("device/events/relpay", makeThunkAction<MutationResponseGraphType>()),
}

const reducerSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setFilterState(state, action: PayloadAction<Partial<FilterState>>) {
      const newFilter: FilterState = {
        ...state.deviceFilter.filterState,
        ...action.payload,
      }

      const filteredDevices = filterDevices(newFilter, state.allDevices)

      state.filteredDevices.devices = filteredDevices
      state.counts[newFilter.filter] = filteredDevices.length

      if (newFilter.filter !== DeviceFilterOptionsEnum.Alert) {
        state.counts = makeFilterCounts(state.allDevices)
      }

      state.deviceFilter = {
        filterState: newFilter,
        description: deviceFilterOptions.find((x) => x.id === newFilter.filter)?.name || "Not-set",
      }
    },
    setCurrentDevice(state, action: PayloadAction<string>) {
      state.currentDevice = state.allDevices[action.payload]
    },
    refreshData(state) {
      // this has the side effect of causing the Map Page toRe Load data again
      state.deviceFilter.filterState.filter = DeviceFilterOptionsEnum.NotSet
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(additionalActions.reloadDeviceData.pending, (state) => {
        state.loading = true
      })
      .addCase(additionalActions.reloadDeviceData.rejected, (state) => {
        state.loading = false
        state.timestamp = Date.now()
        state.allDevices = {}
        state.filteredDevices.devices = []
      })
      .addCase(additionalActions.reloadDeviceData.fulfilled, (state, action) => {
        const response: Array<SingleDeviceResponse> = action.payload
        state.allDevices = {}
        response.forEach((x) => (state.allDevices[x.deviceUrn] = x))
        state.filteredDevices.devices = filterDevices(state.deviceFilter.filterState, state.allDevices)
        state.counts = makeFilterCounts(state.allDevices)
        state.timestamp = Date.now()
        state.loading = false
      })

      .addCase(additionalActions.makeDeviceQueryAction.pending, (state) => {
        state.loading = true
      })
      .addCase(additionalActions.makeDeviceQueryAction.rejected, (state) => {
        state.loading = false
      })
      .addCase(additionalActions.makeDeviceQueryAction.fulfilled, (state, action) => {
        const response: Array<SingleDeviceResponse> = action.payload
        response.forEach((x) => (state.allDevices[x.deviceUrn] = x))
        state.filteredDevices.devices = filterDevices(state.deviceFilter.filterState, state.allDevices)
        state.counts = makeFilterCounts(state.allDevices)
        state.timestamp = Date.now()
        state.loading = false
      })

      .addCase(additionalActions.makeCreateAlarmAction.pending, (state) => {
        state.loading = true
      })
      .addCase(additionalActions.makeCreateAlarmAction.rejected, (state) => {
        state.loading = false
      })
      .addCase(additionalActions.makeCreateAlarmAction.fulfilled, (state, action) => {
        const devices = state.filteredDevices.devices
        const device = action.payload
        if (isDevice(device)) {
          updateSingleDevice(device, devices)
          state.currentDevice = updateCurrentDevice(device, state.currentDevice)
          state.allDevices[device.deviceUrn] = device
          state.counts = makeFilterCounts(state.allDevices)
        }
        state.loading = false
      })

      .addCase(additionalActions.makeDeleteAlarmAction.pending, (state) => {
        state.loading = true
      })
      .addCase(additionalActions.makeDeleteAlarmAction.rejected, (state) => {
        state.loading = false
      })
      .addCase(additionalActions.makeDeleteAlarmAction.fulfilled, (state, action) => {
        const devices = state.filteredDevices.devices
        const device = action.payload
        if (isDevice(device)) {
          updateSingleDevice(device, devices)
          state.currentDevice = updateCurrentDevice(device, state.currentDevice)
          state.allDevices[device.deviceUrn] = device
        }
        state.loading = false
      })

      .addCase(additionalActions.replayEventsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(additionalActions.replayEventsAction.rejected, (state) => {
        state.loading = false
      })
      .addCase(additionalActions.replayEventsAction.fulfilled, (state) => {
        state.loading = false
      })
  },
})

export const DeviceReducer = {
  reducer: reducerSlice.reducer,
  setFilterState: reducerSlice.actions.setFilterState,
  setCurrentDevice: reducerSlice.actions.setCurrentDevice,
  refreshData: reducerSlice.actions.refreshData,
  ...additionalActions,
}
