import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web"
import { ReportCallback } from "web-vitals"
import { makeApplicationConfig } from "./authentication/ApplicationConfiguration"
import { SOFTWARE_VERSION } from "./version"

// Sample query
// customMetrics
// | where name contains "React Component Engaged Time (seconds)"
// | summarize avg(value), count() by tostring(customDimensions["Component Name"])

const { nodeEnv } = makeApplicationConfig()
const reactAppInsightsPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactAppInsightsPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    // see: https://github.com/microsoft/ApplicationInsights-JS/issues/1905
    // correlationHeaderExcludedDomains: [""]

    disableTelemetry: nodeEnv === "production" ? false : true,
    samplingPercentage: 50,
  },
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags = envelope.tags || []
  envelope.tags["ai.cloud.role"] = "pollin8.ui"
  envelope.tags["ai.cloud.roleInstance"] = "device.ui"
  envelope.tags["p8.version"] = SOFTWARE_VERSION.softwareVersion
})

const reportWebVitals = (onPerfEntry?: ReportCallback) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
      onCLS(onPerfEntry)
      onFID(onPerfEntry)
      onFCP(onPerfEntry)
      onLCP(onPerfEntry)
      onTTFB(onPerfEntry)
    })
  }
}

export { appInsights, reactAppInsightsPlugin, reportWebVitals }
