import { ApiClientOptions, HeaderBuilder, HttpClient, HttpHeader, HttpResponse } from "./HttpClientTypes"

export function makeHttpAuthHandler(httpClient: HttpClient, clientOptions: ApiClientOptions): HttpClient {
  const makeOAthAuthorizationHeader = (options: ApiClientOptions): HttpHeader | undefined =>
    options.bearerToken ? { key: "Authorization", value: `Bearer ${options.bearerToken}` } : undefined

  const makeFunctionCodeAuthHeader = (options: ApiClientOptions): HttpHeader | undefined =>
    options.functionAccessCode ? { key: "x-functions-key", value: options.functionAccessCode } : undefined

  const extraHeaderBuilders = [makeOAthAuthorizationHeader, makeFunctionCodeAuthHeader]

  const makeDefaultGetHttpHeaders = function (options: ApiClientOptions, builder: Array<HeaderBuilder>) {
    const headers: Array<HttpHeader> = [{ key: "Accept", value: "application/json" }]
    const additionalHeaders: Array<HttpHeader> = builder
      .map((x) => x(options))
      .filter((x): x is HttpHeader => (x ? true : false))

    return headers.concat(additionalHeaders)
  }

  const makeDefaultPostHttpHeaders = function (options: ApiClientOptions, builder: Array<HeaderBuilder>) {
    const headers = [
      { key: "Accept", value: "application/json" },
      { key: "Content-Type", value: "application/json" },
    ]
    const additionalHeaders = builder.map((x) => x(options)).filter((x) => (x ? true : false))
    return headers.concat(additionalHeaders as Array<HttpHeader>)
  }

  // const requiresAuth = (response: HttpResponse): boolean => !options.bearerToken || response.status === 401

  const getAccessToken = (options: ApiClientOptions): Promise<ApiClientOptions> => {
    return options.auth.acquireToken().then((token) => {
      options.bearerToken = token
      return options
    })
  }

  const requestWithRetry = (makeRequest: (options: ApiClientOptions) => Promise<HttpResponse>) =>
    getAccessToken(clientOptions)
      .then((options) => makeRequest(options))
      .then((response) => response)

  return {
    get: (uri: string | undefined, requestHeaders: Array<HttpHeader> = []) =>
      requestWithRetry((options) =>
        httpClient.get(uri, makeDefaultGetHttpHeaders(options, extraHeaderBuilders).concat(requestHeaders)),
      ),
    post: (uri: string, body?: string, requestHeaders?: Array<HttpHeader>) =>
      requestWithRetry((options) =>
        httpClient.post(
          uri,
          body,
          requestHeaders
            ? makeDefaultPostHttpHeaders(options, extraHeaderBuilders).concat(requestHeaders)
            : makeDefaultPostHttpHeaders(options, extraHeaderBuilders),
        ),
      ),
    put: (uri: string, body?: string, requestHeaders?: Array<HttpHeader>) =>
      requestWithRetry((options) =>
        httpClient.put(
          uri,
          body,
          requestHeaders
            ? makeDefaultPostHttpHeaders(options, extraHeaderBuilders).concat(requestHeaders)
            : makeDefaultPostHttpHeaders(options, extraHeaderBuilders),
        ),
      ),

    patch: (uri: string, body?: string, requestHeaders?: Array<HttpHeader>) =>
      requestWithRetry((options) =>
        httpClient.patch(
          uri,
          body,
          requestHeaders
            ? makeDefaultPostHttpHeaders(options, extraHeaderBuilders).concat(requestHeaders)
            : makeDefaultPostHttpHeaders(options, extraHeaderBuilders),
        ),
      ),

    delete: (uri: string, body?: string, requestHeaders?: Array<HttpHeader>) =>
      requestWithRetry((options) =>
        httpClient.delete(
          uri,
          body,
          requestHeaders
            ? makeDefaultPostHttpHeaders(options, extraHeaderBuilders).concat(requestHeaders)
            : makeDefaultPostHttpHeaders(options, extraHeaderBuilders),
        ),
      ),
  }
}
