import { IPublicClientApplication } from "@azure/msal-browser"
import { MsalSilentRequestBuilder } from "../../authentication/authConfig"
import { makeApiClientAuthOptionsFromMSAL, makeApiNounceProvider } from "../../client/GraphQLClient"
import { makeHttpAuthHandler } from "../../client/httpClient/AuthHandler"
import { FetchOptions, makeFetchHttpClient } from "../../client/httpClient/FetchClient"
import { HttpResponse } from "../../client/httpClient/HttpClientTypes"
import {
  HttpTypedResponse,
  RestApiError,
  RestClient,
  makeRestClient,
} from "../../client/httpClient/httpClient"
import { apiConfiguration } from "../apiConfig"
import { ProvisioningApResourceTypes, ProvisioningApiErrorResponse } from "./clientTypes"
import { RestLink } from "./restTypes"
import { SOFTWARE_VERSION } from "../../version"

export interface ProvisioningApiClient {
  loadResource(link: RestLink): Promise<HttpTypedResponse<ProvisioningApResourceTypes>>
  createResource<Response extends object, Request extends object = object>(
    link: RestLink,
    data?: Request,
  ): Promise<HttpTypedResponse<Response>>
  patchResource<Response extends object, Request extends object>(
    link: RestLink,
    resource: Request,
  ): Promise<HttpTypedResponse<Response>>
  putResource<Response extends object, Request extends object>(
    link: RestLink,
    resource: Request,
  ): Promise<HttpTypedResponse<Response>>
  deleteResource<T extends object = object>(link: RestLink): Promise<HttpTypedResponse<T>>
}
export function makeProvisioningClient(instance: IPublicClientApplication): ProvisioningApiClient {
  const { url, domainConfig, scopes } = apiConfiguration.onboardingApi
  // const host = process.env.REACT_APP_API_BASE_URL || window.location.origin
  const host = domainConfig.baseURL
  const tokenRequestOptions = MsalSilentRequestBuilder.make(domainConfig).withScopes(scopes()).toObject()

  const options = makeApiClientAuthOptionsFromMSAL(instance, tokenRequestOptions)
  const fetchOptions: FetchOptions = {
    nounce: makeApiNounceProvider(instance, tokenRequestOptions, SOFTWARE_VERSION.softwareVersion),
  }
  const fetchClient = makeFetchHttpClient(`${host}${url}`, fetchOptions)
  const httpClient = makeHttpAuthHandler(fetchClient, options)
  const restClient = makeRestClient(httpClient, {
    errorFactory: (response) => {
      const contentType = response.headers.find((x) => x.key.toLowerCase() === "content-type")
      const isJson = contentType && contentType.value.startsWith("application/json")
      const responseData = isJson && response.bodyText ? JSON.parse(response.bodyText) : undefined

      return new ProvisioningApiError(response, responseData)
    },
  })
  return makeClient(restClient)
}

function makeClient(client: RestClient): ProvisioningApiClient {
  return {
    loadResource: <T extends object>(link: RestLink) => client.get<T>(link.uri),
    deleteResource: <T extends object = object>(link: RestLink) => client.delete<T>(link.uri),
    createResource: <Response extends object, Request extends object>(link: RestLink, data?: Request) =>
      client.post<Response, Request>(link.uri, data),
    patchResource: <Response extends object, Request extends object>(link: RestLink, resource: Request) =>
      client.patch<Response, Request>(link.uri, resource),
    putResource: <Response extends object, Request extends object>(link: RestLink, resource: Request) =>
      client.put<Response, Request>(link.uri, resource),
  }
}

class ProvisioningApiError extends RestApiError<ProvisioningApiErrorResponse> {
  constructor(
    public response: HttpResponse,
    data?: ProvisioningApiErrorResponse,
  ) {
    super(response, data)
  }
}

export function isProvisioningApiError(e?: unknown): e is ProvisioningApiError {
  const maybeError = e as Partial<ProvisioningApiError>
  if (!maybeError) return false
  return maybeError instanceof ProvisioningApiError ? true : false
}
