import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser"
import { apiConfiguration } from "../api/apiConfig"
import { makeApplicationConfig, PolicyNames } from "./ApplicationConfiguration"
import { RedirectRequestBuilder } from "./authConfig"

export function signIn(
  instance: IPublicClientApplication,
  scopes: Array<string> = apiConfiguration.graphQLApi.scopes(),
): Promise<void> {
  const domainConfig = makeApplicationConfig()
  const signInRequest = RedirectRequestBuilder.make(domainConfig)
    .withScopes(["openid"])
    .withScopes(scopes)
    .withPolicyName(domainConfig.signInPolicyName)
    .toObject()

  return instance.loginRedirect(signInRequest)
}

export function signOut(instance: IPublicClientApplication): Promise<void> {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  return instance.logoutRedirect()
}

export function resetPassword(instance: IPublicClientApplication): Promise<void> {
  const domainConfig = makeApplicationConfig()
  const resetPasswordRequest = RedirectRequestBuilder.make(domainConfig)
    .withPolicy(PolicyNames.PasswordReset)
    .toObject()
  return instance.loginRedirect(resetPasswordRequest).then(() => {
    window.alert("Password has been reset successfully. \nPlease sign-in with your new password.")
  })
}

export async function getAccessToken(
  instance: IPublicClientApplication,
  request: SilentRequest,
): Promise<string | null | undefined> {
  return getToken(instance, request).then((x) => x?.accessToken)
}

export function setAccount(instance: IPublicClientApplication, account?: AccountInfo | null) {
  if (account) {
    instance.setActiveAccount(account)
  }
}

export function getToken(
  instance: IPublicClientApplication,
  request: SilentRequest,
): Promise<AuthenticationResult | undefined> {
  const account = instance.getActiveAccount()
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  // const homeAccount = instance.getAccountByHomeId(accountId)
  // request.account = homeAccount ? homeAccount : undefined
  request.account = account ? account : undefined
  request.forceRefresh = false

  /**
   *
   */
  return instance
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        console.warn("Silent token acquisition fails. No access token.", response)
        // throw new InteractionRequiredAuthError()
      }

      // console.log("access_token acquired at: " + new Date().toString())
      return response
    })
    .catch((error) => {
      console.error("Silent token acquisition fails. Acquiring token using redirect.", error)
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request).then(undefined)
      } else {
        console.log(error)
      }
      return undefined
    })
}
