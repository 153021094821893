import { HttpClient, HttpResponse } from "./HttpClientTypes"
export type HttpTypedResponse<T extends object> = HttpResponse & { data: T }

export interface RestClient {
  get<T extends object>(path: string): Promise<HttpTypedResponse<T>>
  post<Response extends object, Request extends object>(
    path: string,
    data?: Request,
  ): Promise<HttpTypedResponse<Response>>

  patch<Response extends object, Request extends object>(
    path: string,
    data?: Request,
  ): Promise<HttpTypedResponse<Response>>

  put<Response extends object, Request extends object>(
    path: string,
    data?: Request,
  ): Promise<HttpTypedResponse<Response>>

  delete<T extends object>(path: string): Promise<HttpTypedResponse<T>>
}

export interface RestApiOptions {
  errorFactory: (response: HttpResponse) => RestApiError
}

const defaultOptions: RestApiOptions = {
  errorFactory: (response: HttpResponse) => {
    const contentType = response.headers.find((x) => x.key.toLowerCase() === "content-type")
    const isJson = contentType && contentType.value.startsWith("application/json")
    const responseData = isJson && response.bodyText ? JSON.parse(response.bodyText) : undefined

    return new RestApiError(response, responseData)
  },
}
export function makeRestClient(client: HttpClient, options?: Partial<RestApiOptions>): RestClient {
  const op = {
    ...defaultOptions,
    ...options,
  }

  return {
    get: (path: string) => getData(client, path, op),
    post: <Response extends object, Request extends object>(path: string, data?: Request) =>
      postData<Response, Request>(client, path, op, data),
    patch: <Response extends object, Request extends object>(path: string, data?: Request) =>
      patchData<Response, Request>(client, path, op, data),
    put: <Response extends object, Request extends object>(path: string, data?: Request) =>
      puthData<Response, Request>(client, path, op, data),
    delete: <Response extends object>(path: string) => deleteData<Response>(client, path, op),
  }
}

function getData<T extends object>(
  client: HttpClient,
  path: string,
  options: RestApiOptions,
): Promise<HttpTypedResponse<T>> {
  return client
    .get(path)
    .then(makeStatusValidator(options))
    .then(validateBody)
    .then((response) => parseJsonResponse<T>(response))
}

function postData<Response extends object, Request extends object = object>(
  client: HttpClient,
  path: string,
  options: RestApiOptions,
  data?: Request,
): Promise<HttpTypedResponse<Response>> {
  return client
    .post(path, data ? JSON.stringify(data) : undefined)
    .then(makeStatusValidator(options))
    .then(validateBody)
    .then((response) => parseJsonResponse<Response>(response))
}

function patchData<Response extends object, Request extends object = object>(
  client: HttpClient,
  path: string,
  options: RestApiOptions,
  data?: Request,
): Promise<HttpTypedResponse<Response>> {
  return client
    .patch(path, data ? JSON.stringify(data) : undefined)
    .then(makeStatusValidator(options))
    .then(validateBody)
    .then((response) => parseJsonResponse<Response>(response))
}

function puthData<Response extends object, Request extends object = object>(
  client: HttpClient,
  path: string,
  options: RestApiOptions,
  data?: Request,
): Promise<HttpTypedResponse<Response>> {
  return client
    .put(path, data ? JSON.stringify(data) : undefined)
    .then(makeStatusValidator(options))
    .then(validateBody)
    .then((response) => parseJsonResponse<Response>(response))
}

function deleteData<T extends object>(
  client: HttpClient,
  path: string,
  options: RestApiOptions,
): Promise<HttpTypedResponse<T>> {
  return client
    .delete(path)
    .then(makeStatusValidator(options))
    .then(validateBody)
    .then((response) => parseJsonResponse<T>(response))
}

function makeStatusValidator(options: RestApiOptions) {
  return (response: HttpResponse) => {
    if (response.status < 400) {
      return response
    }
    throw options.errorFactory(response)
  }
}

function validateBody(response: HttpResponse) {
  if (!response.bodyText || response.bodyText.trim().length === 0) {
    throw new Error(`${response.requestVerb} '${response.requestUri}' response doesn't have any content`)
  }

  return response
}

function parseJsonResponse<T extends object>(response: HttpResponse): HttpTypedResponse<T> {
  try {
    return response.bodyText
      ? { ...response, data: JSON.parse(response.bodyText) }
      : { ...response, data: undefined }
  } catch {
    throw new Error(`${response.requestVerb} '${response.requestUri}' response json could not be parsed`)
  }
}

export class RestApiError<T extends object = Record<string, unknown>> extends Error {
  constructor(
    public response: HttpResponse,
    public responseData?: T,
  ) {
    super(`${response.requestVerb} '${response.requestUri}' returned status code: ${response.status}`)
  }
}

export function isRestApiError(e?: unknown): e is RestApiError {
  const maybeError = e as Partial<RestApiError>
  if (!maybeError) return false
  return maybeError instanceof RestApiError ? true : false
}
