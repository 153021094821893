import {
  AccountInfo,
  Configuration,
  LogLevel,
  ProtocolMode,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser"
import { DomainConfiguration, PolicyNames, makeApplicationConfig } from "./ApplicationConfiguration"

const { isIE, isEdge, isFirefox } = detectBrowserVersions()

export class MsalConfig {
  // Msal Configurations
  static makeMsalConfig(domainConfig: DomainConfiguration): Configuration {
    // const knownAuthorities = Object.values(PolicyNames).map((policy) => makeAuthority(domainConfig, policy))
    // knownAuthorities.push("https://login.microsoftonline.com/common")

    const msalConfig: Configuration = {
      auth: {
        clientId: domainConfig.clientId,

        // see : https://github.com/Azure-Samples/ms-identity-b2c-javascript-spa/blob/main/App/policies.js
        knownAuthorities: [makeAuthorityDomain(domainConfig)],

        // Must be one of the known authorities
        authority: makeAuthority(domainConfig, domainConfig.signInPolicyName),

        //redirectUri: 'https://jwt.ms'
        redirectUri: window.location.origin, // /auth.html`
        postLogoutRedirectUri: window.location.origin,

        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: false,

        protocolMode: ProtocolMode.AAD,
      },

      // cache: {
      //   cacheLocation: "sessionStorage",
      //   storeAuthStateInCookie: false,
      // },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
      },

      system: {
        loggerOptions: {
          logLevel: LogLevel.Warning,
          loggerCallback: logger,
          piiLoggingEnabled: false,
        },
      },
    }

    return msalConfig
  }
}

export class MsalSilentRequestBuilder {
  private constructor(
    private config: DomainConfiguration,
    private request: SilentRequest,
  ) {}

  static make(config: DomainConfiguration): MsalSilentRequestBuilder {
    const silentloginRequest: SilentRequest = {
      scopes: [],
      forceRefresh: false,
    }

    return new MsalSilentRequestBuilder(config, silentloginRequest)
  }

  withAccount(account: AccountInfo | undefined | null): this {
    if (account) this.request.account = account
    return this
  }
  withPolicy(policyName: PolicyNames): this {
    this.request.authority = makeAuthority(this.config, policyName)
    return this
  }
  withPolicyName(policyName: string): this {
    this.request.authority = makeAuthority(this.config, policyName)
    return this
  }

  withAuthority(authority: string): this {
    this.request.authority = authority
    return this
  }

  withScopes(scopes: Array<string>): this {
    scopes.forEach((s) => this.request.scopes.push(s))
    return this
  }

  toObject(): SilentRequest {
    return this.request
  }
}

export class RedirectRequestBuilder {
  private constructor(
    private config: DomainConfiguration,
    private request: RedirectRequest,
  ) {}
  static make(config: DomainConfiguration = makeApplicationConfig()): RedirectRequestBuilder {
    const request: RedirectRequest = {
      // authority: makeAuthority(domainConfig, policyName),
      // domainHint: `${config.authorityDomainName}.onmicrosoft.com`,
      scopes: [],
    }

    return new RedirectRequestBuilder(config, request)
  }

  withPolicy(policyName: PolicyNames): this {
    this.request.authority = makeAuthority(this.config, policyName)
    return this
  }
  withPolicyName(policyName: string): this {
    this.request.authority = makeAuthority(this.config, policyName)
    return this
  }
  withScopes(scopes: Array<string>): this {
    scopes.forEach((s) => this.request.scopes.push(s))
    return this
  }

  toObject(): RedirectRequest {
    return this.request
  }
}

/**
 * Browser check variables
 * If you support IE, our recommendation is that you sign-in using Redirect APIs
 * If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
 */
function detectBrowserVersions() {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf("MSIE ")
  const msie11 = ua.indexOf("Trident/")
  const msedge = ua.indexOf("Edge/")
  const firefox = ua.indexOf("Firefox")

  const isIE = msie > 0 || msie11 > 0
  const isEdge = msedge > 0
  const isFirefox = firefox > 0 // Only needed if you need
  return { isIE, isEdge, isFirefox }
}

/** As per the docs the autority contains the Policy name
 *  See  here for applicable authorities
   https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-client-application-configuration#authority
*/
function makeAuthority(config: DomainConfiguration, policyName: string) {
  return `https://${config.authorityDomainName}.b2clogin.com/${config.authorityDomainName}.onmicrosoft.com/${policyName}`
}

function makeAuthorityDomain(config: DomainConfiguration) {
  return `${config.authorityDomainName}.b2clogin.com`
}

const logger = (level: LogLevel, message: string, containsPii: boolean): void => {
  if (containsPii) {
    return
  }
  switch (level) {
    case LogLevel.Error:
      // eslint-disable-next-line no-console
      console.error(message)
      return
    case LogLevel.Info:
      // eslint-disable-next-line no-console
      console.info(message)
      return
    case LogLevel.Verbose:
      // eslint-disable-next-line no-console
      console.debug(message)
      return
    case LogLevel.Warning:
      // eslint-disable-next-line no-console
      console.warn(message)
      return
  }
}
