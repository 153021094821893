/* eslint-disable no-console */
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"
import React, { useEffect, useState } from "react"
import * as serviceWorkerRegistration from "../serviceWorkerRegistration"

let waitingServiceWorker: ServiceWorker | null = null

const ServiceWorkerRegistrationComponentBase: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)
  useEffect(() => {
    console.log("Registering service worker")
    serviceWorkerRegistration.register({
      onUpdate: handleServiceWorkerUpdateAvailable,
      onRegistrationActive: handleServiceActive,
    })
  }, [])

  useEffect(() => {
    const modal = document.getElementById("new-sw-version-modal")
    if (modal) modal.style.display = showMessage ? "block" : "none"
  }, [showMessage])

  const handleServiceWorkerUpdateAvailable = (registration: ServiceWorkerRegistration) => {
    console.log("Service worker: Update available triggered", !!registration.waiting)
    if (registration.waiting) {
      waitingServiceWorker = registration.waiting
      console.log("Service Worker: Requested software update")
      if (!newVersionAvailable) setNewVersionAvailable(true)
      if (!showMessage) setShowMessage(true)
    }
  }

  const handleServiceActive = (registration: ServiceWorkerRegistration) => {
    // On the active worker check for updates
    return setInterval(async () => await pollForSoftwareUpdates(registration), 10 * 60 * 1000)
  }

  async function pollForSoftwareUpdates(registration: ServiceWorkerRegistration) {
    console.log("Service Worker: check for updates")
    if (registration.waiting) handleServiceWorkerUpdateAvailable(registration)
    else await registration.update()
  }

  // const handleReloadClicked = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   ev.preventDefault()
  //   ev.stopPropagation()
  //   window.location.reload()
  // }

  const handleReloadClicked = () => {
    // Blair new code
    // https://github.com/deanhume/pwa-update-available/blob/master/index.html
    console.info("Service Worker: Trigger SKIP_WAITING", !!waitingServiceWorker)
    if (!waitingServiceWorker)
      console.warn("Service Worker: Trigger SKIP_WAITING, but no waiting worker configured")
    waitingServiceWorker?.postMessage({ type: "SKIP_WAITING" })
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return
    setShowMessage(false)
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleReloadClicked}>
        UPDATE NOW
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      open={showMessage}
      autoHideDuration={9 * 60 * 1000}
      onClose={handleClose}
      message="New Software Version Available"
      action={action}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    />
  )
}

export const ServiceWorkerRegistrationComponent = ServiceWorkerRegistrationComponentBase
