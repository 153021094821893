import { Typography } from "@mui/material"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import React from "react"
import { Role, User } from "../../../../api/provisioning/clientTypes"
import { UserAvatarComponent } from "../../../../components/UserAvatarComponent"
import { UserRolesView } from "./UserRolesView"

type ComponentProps = {
  user: User
  roles: Array<Role>
}

export const UserProfileView: React.FC<ComponentProps> = (props) => {
  const { user, roles } = props

  const displayName = (
    <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
      {user.displayName}
    </Typography>
  )

  const showRoles = roles?.length > 0
  return (
    <List sx={{ p: 0, m: 0 }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <UserAvatarComponent displayName={user.displayName} />
        </ListItemAvatar>
        <ListItemText primary={displayName} secondary={user.identity ?? ""} />
      </ListItem>

      {showRoles && (
        <ListItem>
          <UserRolesView userRoles={roles} />
        </ListItem>
      )}
    </List>
  )
}

// TODO : manage user roles
