import React, { useEffect } from "react"
import { useGeolocated } from "react-geolocated"
import { connect } from "react-redux"
import { UserReducer, UserReducerState } from "../redux/reducers/userReducer"
import { AppDispatch, RootState } from "../redux/store/rootStore"

interface OwnProps {
  showPosition?: boolean
}
interface StateProps {
  position?: { lat: number; lon: number }
}
interface DispatchProps {
  setPosition(lat: number, lon: number, altitude: number | null | undefined)
}

type ComponentProps = OwnProps & StateProps & DispatchProps

const WatchPosition: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { position, showPosition } = props
  const { isGeolocationEnabled, isGeolocationAvailable, coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    isOptimisticGeolocationEnabled: false,
    watchLocationPermissionChange: true,
    watchPosition: true,
  })

  useEffect(() => {
    if (
      isGeolocationEnabled &&
      isGeolocationAvailable &&
      coords &&
      coords.latitude !== 0 &&
      coords.longitude !== 0
    ) {
      if (position) {
        if (position.lat !== coords.latitude || position.lon !== coords.longitude) {
          props.setPosition(coords.latitude, coords.longitude, coords.altitude)
        }
      } else {
        props.setPosition(coords.latitude, coords.longitude, coords.altitude)
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled, position, props, showPosition])

  const locationString = coords ? `${coords.latitude},${coords.longitude}` : ""
  return showPosition ? <span>{locationString}</span> : null
}

function mapStateToProps(state: RootState): StateProps {
  const userReducerState: UserReducerState = state.user
  return {
    position: userReducerState.position,
  }
}

function mapDispatchProps(dispatch: AppDispatch): DispatchProps {
  return {
    setPosition: (lat: number, lon: number, altitude: number | null | undefined) => {
      const alt = altitude ? altitude : undefined
      return dispatch(UserReducer.setPosition({ lat, lon, altitude: alt }))
    },
  }
}

export const WatchPositionComponent = connect(mapStateToProps, mapDispatchProps)(WatchPosition)
