import { UserManagementReducer } from "."
import {
  ProvisioningApResourceTypes,
  RoleResource,
  resourceRelation,
  resourceSelfUri,
} from "../../../api/provisioning/clientTypes"
import { RestFeed } from "../../../api/provisioning/restTypes"
import { findCollection } from "./resourceAccessors"

export function setResourceRaw(state: UserManagementReducer, resource: ProvisioningApResourceTypes): void {
  const selfUri = resourceSelfUri(resource)
  state.allResources[selfUri] = resource

  if (resource.type === "root") {
    state.apiRoot = resource
  }

  if (resource.type === "tenant") {
    state.currentTenant = resource
  }

  if (resource.type === "user" && resource.properties.isCurrent) {
    state.currentUser = { user: resource }
  }

  if (state.currentUser?.user) {
    if (resource.type === "user.profile") {
      if (resourceRelation(resource, "user")?.uri === resourceSelfUri(state.currentUser.user)) {
        state.currentUser.profile = resource
      }
    }
  }

  if (state.selectedUser?.user) {
    if (resource.type === "user.profile") {
      if (resourceSelfUri(resource) === resourceSelfUri(state.selectedUser.user)) {
        state.selectedUser.profile = resource
        const roles = findCollection<RoleResource>(state, state.selectedUser.user, "user.role-collection")
        if (roles) state.selectedUser.roles = roles
      }
    }
  }
}

export function setFeedResourceRaw(state: UserManagementReducer, payload: RestFeed): void {
  const selfLink = resourceRelation(payload, "self")
  state.allFeeds[selfLink.uri] = payload

  if (state.currentUser?.user && payload.feedType === "user.role-collection") {
    if (resourceRelation(payload, "user").uri === resourceSelfUri(state.currentUser.user)) {
      const roles = findCollection<RoleResource>(state, state.currentUser.user, "user.role-collection")
      if (roles) state.currentUser.roles = roles
    }
  }

  if (state.selectedUser?.user && payload.feedType === "user.role-collection") {
    if (resourceRelation(payload, "user").uri === resourceSelfUri(state.selectedUser.user)) {
      state.selectedUser.roles = findCollection<RoleResource>(
        state,
        state.selectedUser.user,
        "user.role-collection",
      )
    }
  }
}
