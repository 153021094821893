import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import "azure-maps-control/dist/atlas.min.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router"
import { RouteObject, createBrowserRouter } from "react-router-dom"
import App from "./App"
import { myMSALObj } from "./authentication/authRedirect"
import { lazyLoad } from "./components/lazyLoad"
import "./index.css"
import { ErrorPage } from "./pages/Error/errorPage"
import { rootStore } from "./redux/store/rootStore"
import theme from "./theme"
import Pollin8Theme from "./theme/Pollin8Theme"

import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"
import { reactAppInsightsPlugin } from "./applicationInsights"

// Lazy Loaded now
// import { DeviceMapPageComponent } from "./pages/DeviceMapPage/DeviceMapPage"
// import { DeviceManagementPageComponent } from "./pages/DeviceManagementPage/DeviceManagementPage"
// import { UserManagementPageComponent } from "./pages/UserManagementPage/UserManagementPage"
// import { DeviceHsitoryPageComponent } from "./pages/DeviceHistoryPage/DeviceHistoryPage"
// import { DashboardsPageComponent } from "./pages/Dashboards/DashboardsPage"
// import { MovePageComponent } from "./pages/MovePage/MovePage"
// import { DisagnosticsPageComponent } from "./pages/DiagnosticsPage/DiagnosticsPage"
// import { UserProfilePageComponent } from "./pages/UserProfilePage/UserProfilePage"
// import { AddLogicalDevicePage } from "./pages/DeviceManagementPage/pages/AddLogicalDevice"

export enum RoutePaths {
  Dashboards = "/dashboards",
  Diagnostics = "/diagnostics",
  DeviceManagment = "/management/device",
  UserManagement = "/management/user",
}

const DisagnosticsPageComponent = lazyLoad(
  () => import("./pages/DiagnosticsPage/DiagnosticsPage"),
  "DisagnosticsPageComponent",
)

const DashboardsPageComponent = lazyLoad(
  () => import("./pages/Dashboards/DashboardsPage"),
  "DashboardsPageComponent",
)
const DeviceMapPageComponent = lazyLoad(
  () => import("./pages/DeviceMapPage/DeviceMapPage"),
  "DeviceMapPageComponent",
)

const DeviceHsitoryPageComponent = lazyLoad(
  () => import("./pages/DeviceHistoryPage/DeviceHistoryPage"),
  "DeviceHsitoryPageComponent",
)
const TransferDevicePage = lazyLoad(() => import("./pages/MovePage/MovePage"), "MovePageComponent")
const DeviceManagementPageComponent = lazyLoad(
  () => import("./pages/DeviceManagementPage/DeviceManagementPage"),
  "DeviceManagementPageComponent",
)
const UserManagementPageComponent = lazyLoad(
  () => import("./pages/UserManagementPage/UserManagementPage"),
  "UserManagementPageComponent",
)

const UserProfilePageComponent = lazyLoad(
  () => import("./pages/UserProfilePage/UserProfilePage"),
  "UserProfilePageComponent",
)

const AddLogicalDevicePage = lazyLoad(
  () => import("./pages/DeviceManagementPage/pages/AddLogicalDevice"),
  "AddLogicalDevicePage",
)

function makeContentRouter(): Array<RouteObject> {
  const contentRouter: Array<RouteObject> = [
    {
      index: true,
      element: <DeviceMapPageComponent />,
    },
    {
      path: "diagnostics",
      element: <DisagnosticsPageComponent />,
    },
    {
      path: "dashboards",
      element: <DashboardsPageComponent />,
    },
    {
      path: "device-history/:logicalDeivceId",
      element: <DeviceHsitoryPageComponent />,
    },
    {
      path: "move/:logicalDeviceId",
      element: <TransferDevicePage />,
    },

    {
      path: "management/user",
      element: <UserManagementPageComponent pageMode={"read"} />,
    },
    {
      path: "/management/user/create",
      element: <UserManagementPageComponent pageMode={"create"} />,
    },
    {
      path: "/management/user-profile/edit/:userId",
      element: <UserProfilePageComponent />,
    },
    {
      path: "management/device",
      element: <DeviceManagementPageComponent />,
      // loader:
    },
    {
      path: "management/device/create",
      element: <AddLogicalDevicePage />,
      // loader:
    },
    {
      path: "*",
      element: <DeviceMapPageComponent />,
    },
  ]
  return contentRouter
}

// "@simbathesailor/use-what-changed": "~2.0.0",
// setUseWhatChange(process.env.NODE_ENV === "development")
// Content rendered in <Outlet />
const makeRouter = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <App pca={myMSALObj} />,
      children: makeContentRouter(),
      errorElement: <ErrorPage />,
    },
  ])

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactAppInsightsPlugin}>
      <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactAppInsightsPlugin}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />

          <Provider store={rootStore}>
            <ThemeProvider theme={Pollin8Theme}>
              <RouterProvider router={makeRouter()} />
            </ThemeProvider>
          </Provider>
        </ThemeProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)
