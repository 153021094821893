import { GeoJsonPoint } from "../../common/geometry"
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTimeOffset: string
  GeoJsonPoint: GeoJsonPoint
  Long: number
}

export type AlarmType = {
  createdAt: Scalars["DateTimeOffset"]
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  isTriggered: Scalars["Boolean"]
  name: Scalars["String"]
  status: Scalars["String"]
  threshold?: Maybe<Scalars["String"]>
  triggerType?: Maybe<MeasurementType>
  version: Scalars["Long"]
}

export type BinlevelType = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    batteryVoltage: Scalars["Float"]
    binLevel?: Maybe<Scalars["Int"]>
    /** The Id of the BinlevelType */
    binlevelTypeId: Scalars["String"]
    deviceUrn: Scalars["String"]
    emptyDistance?: Maybe<Scalars["Float"]>
    fullDistance?: Maybe<Scalars["Float"]>
    hardwareId: Scalars["String"]
    /** The Global Id of the BinlevelType */
    id: Scalars["ID"]
    laserDistance?: Maybe<Scalars["Float"]>
    laserReflectance?: Maybe<Scalars["Int"]>
    lastTiltEventTimestamp?: Maybe<Scalars["DateTimeOffset"]>
    location?: Maybe<Scalars["GeoJsonPoint"]>
    motionFlag?: Maybe<Scalars["Boolean"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    schemaUrn: Scalars["String"]
    temperature: Scalars["Float"]
    tenantUrn: Scalars["String"]
    tiltAngle?: Maybe<Scalars["Int"]>
    tiltFlag?: Maybe<Scalars["Boolean"]>
    timestamp: Scalars["DateTimeOffset"]
    ultrasonicDistance?: Maybe<Scalars["Float"]>
    ultrasoundVariance?: Maybe<Scalars["Float"]>
  }

export type BoltV2Type = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    /** @deprecated Use vbat ?? */
    batteryVoltage?: Maybe<Scalars["Float"]>
    /** The Id of the BoltV2Type */
    boltV2TypeId: Scalars["String"]
    deviceUrn: Scalars["String"]
    hardwareId: Scalars["String"]
    heading?: Maybe<Scalars["Float"]>
    /** The Global Id of the BoltV2Type */
    id: Scalars["ID"]
    location?: Maybe<Scalars["GeoJsonPoint"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    odometer?: Maybe<Scalars["Float"]>
    schemaUrn: Scalars["String"]
    sequenceNumber: Scalars["Int"]
    speed?: Maybe<Scalars["Float"]>
    temperature?: Maybe<Scalars["Float"]>
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
    tripDistance?: Maybe<Scalars["Float"]>
    tripRunTime?: Maybe<Scalars["Float"]>
    vbat?: Maybe<Scalars["Float"]>
    vext?: Maybe<Scalars["Float"]>
  }

export type Device =
  | BinlevelType
  | BoltV2Type
  | DualBatteryVoltageType
  | Oyster3BluetoothType
  | Oyster3Type
  | OysterType
  | PeopleCounterType

export type DeviceConfigurationType = {
  enabled: Scalars["Boolean"]
  hardwareDeviceId: Scalars["String"]
  logicalDeviceId?: Maybe<Scalars["String"]>
  schema?: Maybe<Scalars["String"]>
  tenant: Scalars["String"]
  timestamp: Scalars["DateTimeOffset"]
}

export type DeviceIdentifier = {
  deviceUrn: Scalars["String"]
  hardwareId: Scalars["String"]
  schemaUrn: Scalars["String"]
  tenantUrn: Scalars["String"]
}

export type DualBatteryVoltageType = DeviceIdentifier &
  Node & {
    alarms: Array<Maybe<AlarmType>>
    batteryVoltage: Scalars["Float"]
    deviceUrn: Scalars["String"]
    /** The Id of the DualBatteryVoltageType */
    dualBatteryVoltageTypeId: Scalars["String"]
    externalVoltage: Scalars["Float"]
    hardwareId: Scalars["String"]
    /** The Global Id of the DualBatteryVoltageType */
    id: Scalars["ID"]
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    schemaUrn: Scalars["String"]
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
  }

/** The type of alarm trigger evaluation for this alarm */
export enum MeasurementType {
  /** The alarm trigger compares the value of two scalr/numeric values */
  Boolean = "BOOLEAN",
  /** The alarm compares the positions of two geolocation points */
  Geolocation = "GEOLOCATION",
  /** Uninitialised default value */
  None = "NONE",
  /** The alarm trigger compares the value of two scalr/numeric values */
  Scalar = "SCALAR",
}

export type Node = {
  /** Global node Id */
  id?: Maybe<Scalars["ID"]>
}

export type Oyster3BluetoothType = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    deviceUrn: Scalars["String"]
    hardwareId: Scalars["String"]
    heading?: Maybe<Scalars["Float"]>
    /** The Global Id of the Oyster3BluetoothType */
    id: Scalars["ID"]
    location?: Maybe<Scalars["GeoJsonPoint"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    odometer?: Maybe<Scalars["Float"]>
    /** The Id of the Oyster3BluetoothType */
    oyster3BluetoothTypeId: Scalars["String"]
    schemaUrn: Scalars["String"]
    sequenceNumber: Scalars["Int"]
    speed?: Maybe<Scalars["Float"]>
    temperature?: Maybe<Scalars["Float"]>
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
    tripDistance?: Maybe<Scalars["Float"]>
    tripRunTime?: Maybe<Scalars["Float"]>
    vbat?: Maybe<Scalars["Float"]>
    vext?: Maybe<Scalars["Float"]>
  }

export type Oyster3Type = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    deviceUrn: Scalars["String"]
    hardwareId: Scalars["String"]
    heading?: Maybe<Scalars["Float"]>
    /** The Global Id of the Oyster3Type */
    id: Scalars["ID"]
    location?: Maybe<Scalars["GeoJsonPoint"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    odometer?: Maybe<Scalars["Float"]>
    /** The Id of the Oyster3Type */
    oyster3TypeId: Scalars["String"]
    schemaUrn: Scalars["String"]
    sequenceNumber: Scalars["Int"]
    speed?: Maybe<Scalars["Float"]>
    temperature?: Maybe<Scalars["Float"]>
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
    tripDistance?: Maybe<Scalars["Float"]>
    tripRunTime?: Maybe<Scalars["Float"]>
    vbat?: Maybe<Scalars["Float"]>
    vext?: Maybe<Scalars["Float"]>
  }

export type OysterType = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    batteryVoltage: Scalars["Float"]
    deviceUrn: Scalars["String"]
    fixFailed?: Maybe<Scalars["Boolean"]>
    gps_fix_timestamp?: Maybe<Scalars["DateTimeOffset"]>
    hardwareId: Scalars["String"]
    heading?: Maybe<Scalars["Float"]>
    /** The Global Id of the OysterType */
    id: Scalars["ID"]
    inTrip?: Maybe<Scalars["Boolean"]>
    location?: Maybe<Scalars["GeoJsonPoint"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    /** The Id of the OysterType */
    oysterTypeId: Scalars["String"]
    schemaUrn: Scalars["String"]
    speed?: Maybe<Scalars["Float"]>
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
  }

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>
}

export type PeopleCounterType = DeviceIdentifier &
  Node & {
    address?: Maybe<Scalars["String"]>
    alarms: Array<Maybe<AlarmType>>
    batteryVoltage: Scalars["Float"]
    delta: Scalars["Int"]
    deviceUrn: Scalars["String"]
    hardwareId: Scalars["String"]
    /** The Global Id of the PeopleCounterType */
    id: Scalars["ID"]
    location?: Maybe<Scalars["GeoJsonPoint"]>
    /** @deprecated Will be removed or replaced with a user configurable name */
    name: Scalars["String"]
    /** The Id of the PeopleCounterType */
    peopleCounterTypeId: Scalars["String"]
    schemaUrn: Scalars["String"]
    tenantUrn: Scalars["String"]
    timestamp: Scalars["DateTimeOffset"]
    totalCount: Scalars["Int"]
  }

export enum ResponseStatus {
  Error = "ERROR",
  Ok = "OK",
}

export type TenantMutations = {
  createGeoFenceAlarm?: Maybe<MutationResponseGraphType>
  createScalarAlarm?: Maybe<MutationResponseGraphType>
  destroyAlarm?: Maybe<MutationResponseGraphType>
  replayEvents?: Maybe<MutationResponseGraphType>
}

export type TenantMutationsCreateGeoFenceAlarmArgs = {
  input: GeofenceAlarmInput
}

export type TenantMutationsCreateScalarAlarmArgs = {
  input: ScalarAlarmInput
}

export type TenantMutationsDestroyAlarmArgs = {
  input: DestroyAlarmInput
}

export type TenantMutationsReplayEventsArgs = {
  input: IdentifierInput
}

export type TenantQueries = {
  profile?: Maybe<UserProfileType>
  tenant?: Maybe<TenantType>
}

export type TenantQueriesTenantArgs = {
  tenantUrn?: InputMaybe<Scalars["String"]>
}

export type TenantType = {
  alarms: Array<Maybe<AlarmType>>
  dashboardUrl: Scalars["String"]
  device?: Maybe<Device>
  deviceConfig?: Maybe<Array<Maybe<DeviceConfigurationType>>>
  /** Find device history greater than or equal to from date and less than end date (Half open set) */
  deviceHistory?: Maybe<UnionOfGraphDeviceTypesConnection>
  devices: Array<Maybe<Device>>
  id: Scalars["String"]
  name: Scalars["String"]
  tenantUrn: Scalars["String"]
}

export type TenantTypeDeviceArgs = {
  deviceUrn: Scalars["String"]
}

export type TenantTypeDeviceHistoryArgs = {
  after?: InputMaybe<Scalars["String"]>
  deviceUrn: Scalars["String"]
  end?: InputMaybe<Scalars["DateTimeOffset"]>
  first?: InputMaybe<Scalars["Int"]>
  start?: InputMaybe<Scalars["DateTimeOffset"]>
}

export type TenantTypeDevicesArgs = {
  meters?: InputMaybe<Scalars["Int"]>
  nameFilter?: InputMaybe<Scalars["String"]>
  point?: InputMaybe<Scalars["GeoJsonPoint"]>
  take?: InputMaybe<Scalars["Int"]>
}

/** The type of comparison to perform to determine if an alarm should trigger */
export enum TriggerComparison {
  /** The measured value and alarm trigger is greater than or equal to */
  GreaterOrEqual = "GREATER_OR_EQUAL",
  /** The measured value is less than the alarm trigger */
  LessThan = "LESS_THAN",
  /** The measured value and alarm trigger are not the same */
  NotEqual = "NOT_EQUAL",
}

/** A connection from an object to a list of objects of type `UnionOfGraphDeviceTypes`. */
export type UnionOfGraphDeviceTypesConnection = {
  /** A list of all of the edges returned in the connection. */
  edges?: Maybe<Array<Maybe<UnionOfGraphDeviceTypesEdge>>>
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<Device>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars["Int"]>
}

/** An edge in a connection from an object to another object of type `UnionOfGraphDeviceTypes`. */
export type UnionOfGraphDeviceTypesEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars["String"]
  /** The item at the end of the edge */
  node?: Maybe<Device>
}

export type UserProfileType = {
  country?: Maybe<Scalars["String"]>
  displayName: Scalars["String"]
  givenName?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  mail?: Maybe<Scalars["String"]>
  preferredLanguage?: Maybe<Scalars["String"]>
  surname?: Maybe<Scalars["String"]>
  tenantUrn?: Maybe<Scalars["String"]>
  userPrincipalName: Scalars["String"]
}

export type DestroyAlarmInput = {
  deviceUrn?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
}

export type GeofenceAlarmInput = {
  alarmName: Scalars["String"]
  /** If true creates and arms alarm */
  arm?: InputMaybe<Scalars["Boolean"]>
  /** Centre location of Geo-fence alarm */
  centroid?: InputMaybe<Scalars["GeoJsonPoint"]>
  deviceUrn: Scalars["String"]
  operator?: InputMaybe<TriggerComparison>
  /** Property Path of field to monitor */
  propertyPath?: InputMaybe<Scalars["String"]>
  /** Radius In Meters */
  radius: Scalars["Int"]
}

export type IdentifierInput = {
  id: Scalars["ID"]
}

export type MutationResponseGraphType = {
  data?: Maybe<MutationResponseIdGraphType>
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>
  status: ResponseStatus
}

export type MutationResponseIdGraphType = {
  id: Scalars["ID"]
}

export type ScalarAlarmInput = {
  alarmName: Scalars["String"]
  /** If true creates and arms alarm */
  arm?: InputMaybe<Scalars["Boolean"]>
  /** Indicates if other alarms on the deviceof the same type should be removed, after creating */
  destroyExisting?: InputMaybe<Scalars["Boolean"]>
  deviceUrn: Scalars["String"]
  /** Offset to be subtracted from value before evaluating threshold */
  offset?: Scalars["Float"]
  operator?: InputMaybe<TriggerComparison>
  /** Property Path of field to monitor */
  propertyPath: Scalars["String"]
  /** Once threshold reached alarm triggered */
  threshold: Scalars["Float"]
}
